import { AxiosResponse } from 'axios'
import Request from '../../../util/Request'
import { IListStatusItem } from './ListStatus'

const AtualizarStatus = async (
    $id: string,
    $form: {
        label?: string,
        color?: string
    },
    $status: "calendar" | "content" | "finance" | "report-simple",
    success: ((response: IListStatusItem) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.put('admin/status/'+ $status + '/' + $id, $form).then(response => {
    success(response.data.content)
}).catch(error)

export default AtualizarStatus