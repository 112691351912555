import React, {useEffect, useState} from 'react';
import ConsultarContaAdmin from '../../../../services/Admin/ConsultarContaAdmin';
import AtualizarMinhaSenha from '../../../../services/Manager/AtualizarMinhaSenha';
import Warning from '../../../../util/Modals/Warning';
import Success from '../../../../util/Modals/Success';
import Sidebar from '../../../components/Sidebar';
import Button from '../../../components/Form/Button';
import ConsultarMinhaContaManager from '../../../../services/Manager/ConsultarMinhaContaManager';
import SidebarManager from '../../../components/SidebarManager';

export interface IAccountProps {}

const AccountManager: React.FunctionComponent<IAccountProps> = (props) =>  {

    const [myAccount, setmyAccount] = useState({
        name: '',
        email: ''
    })

    const [formData, setFormData] = useState({
        last_password: '',
        new_password: ''
    })

    const [confirmPassword, setConfirmPassword] = useState('')

    useEffect(() => {
        ConsultarMinhaContaManager(response => {
            setmyAccount({
                name: response.data.content.name,
                email: response.data.content.email
            })
        }, error => {
            Warning('Não foi possível recuperar sua conta.')
        })
    }, [])

    const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => 
    {
        event.preventDefault()

        if(confirmPassword === formData.new_password)
        {
            AtualizarMinhaSenha(formData, data => {
                Success('Senha altera com sucesso')
                setFormData({
                    last_password: '',
                    new_password: ''
                })

                setConfirmPassword('')
            }, error => {   
                Warning(error.data.message)
            })
        }else{
            Warning("Confirmação de senha incorreta")
        }
    }

    return (
        <>
            <SidebarManager />
            <div className="body__content">
                <div className="title__component">Minha conta</div> 
                <div>
                    <p className="p__title__detail">Nome</p>
                    <p className="p__content__detail-without-copy">{myAccount.name}</p>
                    <p className="p__title__detail">Email:</p>
                    <p className="p__content__detail-without-copy">{myAccount.email}</p>
                </div>
                <div className="title__component">Alterar minha senha</div> 
                <form onSubmit={handleSubmit}>
                    <div className="input-entry">
                        <p>Senha antiga</p>
                        <input style={{width: '300px'}} onChange={event => {
                            setFormData({...formData, last_password: event.target.value})
                        }} type="password" placeholder="Digite sua senha antiga" value={formData.last_password} />
                    </div>
                    <div className="input-entry">
                        <p>Nova senha</p>
                        <input style={{width: '300px'}} onChange={event => {
                            setFormData({...formData, new_password: event.target.value})
                        }} type="password" placeholder="Digite sua nova senha" value={formData.new_password} />
                    </div>
                    <div className="input-entry">
                        <p>Confirme sua nova senha</p>
                        <input style={{width: '300px'}} onChange={event => {
                            setConfirmPassword(event.target.value)
                        }} type="password" placeholder="Confirme sua nova senha" value={confirmPassword} />
                    </div>
                    <Button buttonConfig={
                        {
                            className:"bkg-blue clr-white",
                            style: {marginTop: '20px'}
                        }
                    }>
                        Alterar minha senha
                    </Button>
                </form>
            </div>
        </>
    );
}

export default AccountManager;