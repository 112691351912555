import React from "react"
import Select from 'react-select'

export interface ISelectBox {
    label?: string,
    id?: string,
    options: Array<any>,
    defaultValue?: any,
    onChange?: any,
    getOptionLabel?: any,
    getOptionValue?: any,
    value?: any
}

const SelectBox = (props: ISelectBox) => {
    return (
        <div className="input-box">
            <label htmlFor={props.id}>{props.label}</label>
            <Select  value={props.value} getOptionLabel={props.getOptionLabel} getOptionValue={props.getOptionValue} options={props.options} onChange={props.onChange} isMulti defaultValue={props.defaultValue} />
        </div>
    )
}

export default SelectBox;