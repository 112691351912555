import { AxiosResponse } from 'axios'
import Request from '../../../util/Request'

export interface IListStatusItem {
    id: string,
    label: string,
    color: string
}

const ListStatus = async (
    $status: "calendar" | "content" | "finance" | "report-simple",
    success: ((response: Array<IListStatusItem>) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.get('/admin/status/'+$status).then(response => {
    success(response.data.content)
}).catch(error)

export default ListStatus