import { AxiosResponse } from 'axios'
import Request from '../../../util/Request'
import { IListStatusItem } from './ListStatus'

const StatusById = async (
    $id: string,
    $status: "calendar" | "content" | "finance" | "report-simple",
    success: ((response: IListStatusItem) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.get(`admin/status/${$status}/${$id}`).then(response => {
    success(response.data.content)
}).catch(error)

export default StatusById