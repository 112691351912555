import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import CriarComment from '../../../../../services/Manager/Comment/CriarComment';
import Error from '../../../../../util/Modals/Error';
import Success from '../../../../../util/Modals/Success';
import Warning from '../../../../../util/Modals/Warning';
import Button from '../../../../components/Form/Button';
import ModalContent from '../../../../components/ModalContent';

export interface ICriarComentarioProps {
    onClose: (() => void),
    statusView: boolean
}

const CriarComentario: React.FunctionComponent<ICriarComentarioProps> = (props) =>  {

    const defaultFormData = {
        comment: '',
        client_id: ''
    }

    const [formData, setFormData] = useState(defaultFormData)

    const { client_id } = useParams<{client_id: string}>()

    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => setFormData({...formData, comment: event.target.value})
    
    const onCreate = (event: React.ChangeEvent<HTMLFormElement>) => 
    {
        event.preventDefault()

        if(formData.client_id === '' || formData.comment === '')
        {
            Warning('Preencha os campos.')
        }else{
            CriarComment(formData, () => {
                Success('Criado com sucesso', () => window.location.reload())
                setFormData(defaultFormData)
            }, () => Error('Houve um erro ao tentar criar este observação.' ))
        }
    }

    useEffect(() => {
        setFormData({...formData, client_id: client_id})
    }, [client_id])

    return (
        <ModalContent onClickClose={props.onClose} show={props.statusView}>
            <form onSubmit={onCreate} method="post">
                <div className="input-entry">
                    <p>Observação</p>
                    <input onChange={onChangeInput} type="text" value={formData.comment} />
                </div>
                <Button buttonConfig={{className: "bkg-blue clr-white" }}>
                    Criar observação
                </Button>
            </form>
        </ModalContent>
    );
}

export default CriarComentario;