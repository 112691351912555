import React, {useEffect, useState, useRef} from 'react';
import { Route, useHistory, useParams } from 'react-router-dom';
import ConsultarSocialMedias from '../../../../../services/Manager/SocialMedia/ConsultarSocialMedias';
import Table, { IColumnTable } from '../../../../components/Table';
import Confirm from '../../../../../util/Modals/Confirm';
import DeletarSocialMedia from '../../../../../services/Manager/SocialMedia/DeletarSocialMedia';
import Success from '../../../../../util/Modals/Success';
import SidebarManager from '../../../../components/SidebarManager';
import ModalContent from '../../../../components/ModalContent';
import Button from '../../../../components/Form/Button';
import ConsultarClientesSocialMedia, { ClientSocialMedia } from '../../../../../services/Manager/Clients/ConsultarClientesSocialMedia';
import ClientsForm from './components/ClientsForm';
import RemoverCliente from '../../../../../services/Manager/SocialMedia/Clients/RemoverCliente';
import ClientModal from '../../../../components/ClientModal';

export interface IGestorSocialMediaProps {}

const Clients: React.FunctionComponent<IGestorSocialMediaProps> = (props) =>  {

    const isMounted = useRef(false)
    const [ModalStatus, setModalStatus] = useState(false)
    const [clientFormType, setclientFormType] = useState<'create' | 'update'>('create')
    const [listClients, setListClient]  = useState<Array<any>>([])
    const [idClient, setidClient] = useState('0')
    const [indexClient, setIndexClient] = useState(0)
    const router = useHistory()
    const {id} = useParams()

    useEffect(() => {
        isMounted.current = true

        ConsultarClientesSocialMedia(id, response => {
            if(isMounted.current)
            {
                setListClient(response)
            }
        }, error => Error('Não foi possível recuperar a lista de clientes'))
        
        return () => {
            isMounted.current = false;
        }
    }, [])

    const columnsTable: IColumnTable = [
        {
            name: 'Nome',
            campo: 'name',
            classNameItem: "bg-left-blue-6",
            componentDefault: (row: ClientSocialMedia) => {
                return (
                    <div className="bg-left-blue-6">
                        <span className="link-text-span" onClick={() => {
                            router.push(`/manager/social-media/${row.user_id}/clients/${row.client_id}`)
                        }}>{row.name}</span>
                    </div>
                )
            }
        }, 
        {
            name: 'Contato',
            campo: 'contact_principal',
            classNameHeader: 'txt-align-center',
            classNameItem: 'justify-center ',
        },
        {
            name: 'Whatsapp',
            campo: 'whatapp_principal',
            classNameHeader: 'txt-align-center',
            classNameItem: 'justify-center ',
        },
        {
            name: 'Status',
            campo: 'label',
            classNameHeader: 'txt-align-center',
            componentDefault: (row: ClientSocialMedia) => {
                return (
                    <div className="justify-center" style={{backgroundColor: row.color, color: "#fff"}}>
                        {row.label}
                    </div>
                )
            }
        },
        {
            name: 'Opções',
            campo: 'options',
            classNameHeader: 'txt-align-center',
            componentDefault: (row: any) => {
                const onClickEdit = () => {
                    setidClient(row.id)
                    setIndexClient(listClients.indexOf(row));
                    setclientFormType('update');
                    setModalStatus(true);
                }

                const onClickDelete = () => {
                    let index = listClients.indexOf(row);
                    
                    Confirm(() => {
                        RemoverCliente(row.id, response => {
                            Success('Apagado com sucesso')

                            let listClientsTemp = listClients;
                                                    
                            listClientsTemp.splice(index, 1)

                            setListClient([...listClientsTemp])
                        }, error => {
                            Error(error.data.message)
                        })
                    }, () => {})
                }

                return (
                    <div className="justify-center">
                        <span className="link-table-action bg-primary" onClick={onClickEdit}>
                            <i className="fas fa-edit"></i>
                            <p>Editar</p>
                        </span>
                        <span className="link-table-action bg-danger" onClick={onClickDelete}>
                            <i className="fas fa-trash-alt"></i>
                            <p>Apagar</p>
                        </span>
                        <span className="link-table-action bg-info" onClick={() => {
                            setStatusView(true)
                            setidClient(row.client_id)
                        }}>
                            <i className="far fa-eye"></i>
                            <p>Informações</p>
                        </span>
                    </div>
                )
            }
        },
    ]

    const [statusView, setStatusView] = useState(false)
    
    return (
        <div>
            <SidebarManager/>
            <ClientModal typeUser="manager" clientId={idClient} statusView={statusView} onCloseModal={() => setStatusView(false)} />
            <ModalContent 
                show={ModalStatus} 
                onClickClose={() => setModalStatus(false)}>
                <ClientsForm 
                    socialMedia={id}
                    onChange={() => { window.location.reload() }}
                    id={idClient} 
                    typeRequest={clientFormType} />
            </ModalContent>
            <div className="body__content">
                <h1 className="title__component">Clientes do Social Media</h1>
                    <div className="list-options-calendar">
                        <Button buttonConfig={{className: "bkg-blue bkg-blue-hover clr-white", onClick: () => {
                            setidClient("0")
                            setclientFormType("create")
                            setModalStatus(true)
                        }}}>
                            Adicionar cliente
                        </Button>
                    </div>
                    <Table columns={columnsTable} data={listClients} />
            </div>
        <div/>
        </div>
    )
}

export default Clients;