import { setToken, setType, removeToken, removeType, getToken, getType } from '../Cookies'

const setSession = ($token: string, $type: string) => {
    setToken($token)
    setType($type)
}

const closeSession = () => {
    removeToken()
    removeType()
}

const getSession = () => {
    return {
        token: getToken(),
        type: getType()
    }
}

const inSession = () => {
    return getToken() && getType()
}

export {
    setSession,
    closeSession,
    getSession,
    inSession
}