import { AxiosResponse } from 'axios'
import Request from '../../../../util/Request'

const CadastrarCliente = async (
    data: {
        client_id: string,
        user_id: string
    },
    success: ((response: AxiosResponse) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.post(`/manager/clients/social-media`, data).then(success).catch(error)


export default CadastrarCliente