import { AxiosResponse } from 'axios'
import Request from '../../../util/Request'

const ConsultarSocialMedia = async (
    id: string,
    success: ((response: AxiosResponse) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.get(`/manager/social-media/${id}`).then(success).catch(error)


export default ConsultarSocialMedia