import { AxiosResponse } from 'axios'
import Request from '../../util/Request'

const CreateReportMonth = async (
    data: {
        date_report: string,
        link: string,
        client_id: string
    },
    success: ((response: AxiosResponse) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.post(`/manager/clients/report-month`, data).then(success).catch(error)


export default CreateReportMonth