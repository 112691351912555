import Modal from "../Modal"

const Success = ($text: string, onClose?: any) => {
    Modal.fire({
        title: 'Sucesso!',
        text: $text,
        icon: 'success',
        onClose: onClose
    })
}

export default Success