import { AxiosResponse } from 'axios'
import Request from '../util/Request'

export interface IMidiaItem {
    id: string
    color: string
    label: string
}

const ConsultMidiaList = async (
    success: ((response: Array<IMidiaItem>) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.get('/information/midia').then(response => {
    success(response.data.content)
}).catch(error)

export default ConsultMidiaList