import React, { useEffect, useState } from 'react'
import { Link, Route, Switch, useParams } from 'react-router-dom'
import ConsultAllReportMonth, { ReportMonthClient, ReportMonthData } from '../../../../services/ReportMonth/ConsultAllReportMonth'
import UpdateReportMonth from '../../../../services/ReportMonth/UpdateReportMonth'
import Success from '../../../../util/Modals/Success'
import ModalContent from '../../../components/ModalContent'
import SidebarManager from '../../../components/SidebarManager'
import Table, { IColumnTable } from '../../../components/Table'
import UpdateReport from './UpdateReport'

export interface IRelatorioMensal {}

const RelatorioMensal: React.FunctionComponent<IRelatorioMensal> = (props) =>  
{


    const { client_id } = useParams<{client_id: string}>()

    const [atualizarRelatorioStatus, setatualizarRelatorioStatus] = useState(false)
    const [idRelatorio, setidRelatorio] = useState<any>('')

    const columnsTable: IColumnTable = [
        {
            name: 'Cliente',
            campo: 'name',
            classNameHeader: 'txt-align-left',
            componentDefault: (row: any) => (
                <div>
                    <Link to={`/manager/relatorio-mensal/${row.client_id}`}>{row.name}</Link>
                </div>
            )
        }
    ]

    const columnsTable2: IColumnTable = [
        {
            name: 'Cliente',
            campo: 'name',
            classNameHeader: 'txt-align-left',
            componentDefault: (e: any) => {
                return <div onClick={() => {
                    setIdReportMonth(e.id)
                    setModalReportMonthUpdate(true)
                }}>
                    <span className="link-text-span">{e.name}</span>
                </div>
            }
        },
        {
            name: 'Data',
            campo: 'date_report',
            classNameHeader: 'txt-align-left'
        },
        {
            name: 'Link',
            campo: 'link',
            classNameHeader: 'txt-align-left',
            componentDefault: (e: ReportMonthData) => {
                return <div>
                    <a href={e.link} target="_blank">Clique aqui para ver o relatório</a>
                </div>
            }
        }
    ]

    const [data, setData] = useState<Array<ReportMonthData>>([])
    const [clients, setClients] = useState<Array<ReportMonthClient>>([])

    useEffect(() => {
        ConsultAllReportMonth(data => {
            setData(data.data)
            setClients(data.clients)
        }, error => {
            // ModalWarning('Houve um erro ao buscar seus dados.')
        })
    }, [])

    const filterDataByClientId = data.filter(item => {
        return item.client_id === client_id
    })

    const [ModalReportMonthUpdate, setModalReportMonthUpdate] = useState(false)
    const [idReportMonth, setIdReportMonth] = useState('')

    return (
        <div>
            <SidebarManager/>
            <div className="body__content">
                <Switch>
                    <Route path="/manager/relatorio-mensal/:client_id" exact>
                        <ModalContent show={ModalReportMonthUpdate} onClickClose={() => setModalReportMonthUpdate(false)}>
                            <UpdateReport reportId={idReportMonth} onUpdate={e => {
                                UpdateReportMonth(idReportMonth, e, () => Success('Atualizado com sucesso!', () => window.location.reload()), () => {})
                            }}/>
                        </ModalContent>
                        <h1 className="title__component">Relatórios deste cliente</h1>
                        <Table columns={columnsTable2} data={filterDataByClientId} />
                    </Route>
                    <Route path="" exact>
                        <h1 className="title__component">Escolha um cliente</h1>
                        <Table columns={columnsTable} data={clients} />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}

export default RelatorioMensal