import { AxiosResponse } from 'axios'
import Request from '../../../util/Request'

export interface ClientSocialMedia {
    id: string
    user_id: string
    client_id: string
    name: string
    contact_principal: string
    whatapp_principal: string
    label: string
    color: string
}

const ConsultarClientesSocialMedia = async (
    id: string,
    success: ((response: Array<ClientSocialMedia>) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.get(`manager/clients/social-media/${id}`).then(response => success(response.data.content)).catch(error)


export default ConsultarClientesSocialMedia