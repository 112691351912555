import { AxiosResponse } from 'axios'
import Request from '../../../../util/Request'

const ConsultarContents = async (
    social_midia: string,
    client_id: string,
    success: ((response: AxiosResponse) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.get(`manager/clients/content/social-media/${social_midia}/${client_id}`).then(success).catch(error)


export default ConsultarContents