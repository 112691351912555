import Modal from "../Modal";

const Confirm = (
    $funcSuccess: (() => void),
    $funcError: (() => void),
    $textConfirm: string = "Sim, deletar!"
) => {
    Modal.fire({
        title: 'Você tem certeza?',
        text: "Você não poderá reverter isso!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: $textConfirm,
        cancelButtonText: 'Não, cancelar!',
    }).then((result) => {
        if (result.isConfirmed) {
            $funcSuccess()
        }else{
            $funcError()
        }
    })
}

export default Confirm;