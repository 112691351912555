import React, {useEffect, useState, useRef, memo} from 'react';
import Sidebar from '../../../components/Sidebar';
import Button from '../../../components/Form/Button';
import ModalContent from '../../../components/ModalContent';
import ClientForm from './components/ClientForm';
import Table, { IColumnTable } from '../../../components/Table';
import { ClientConsult, IClientConsultResponse } from '../../../../services/Admin/Client';
import { setType } from '../../../../util/Cookies';
import Confirm from '../../../../util/Modals/Confirm';
import DeleteClient from '../../../../services/Admin/Client/DeleteClient';
import Success from '../../../../util/Modals/Success';
import ToggleClient from '../../../../services/Admin/Client/ToggleClient';

export interface IAdminClientesProps {}

const Clientes: React.FunctionComponent<IAdminClientesProps> = (props) =>  {

    const isMounted = useRef(false)
    const [ModalStatus, setModalStatus] = useState(false)
    const [clientFormType, setclientFormType] = useState<'create' | 'update'>('create')
    const [listClients, setListClient]  = useState<Array<IClientConsultResponse>>([])
    const [idClient, setidClient] = useState('0')
    const [indexClient, setIndexClient] = useState(0)

    useEffect(() => {
        isMounted.current = true

        ClientConsult(response => {
            if(isMounted.current)
            {
                setListClient(response)
            }
        }, error => Error('Não foi possível recuperar a lista de clientes'))
        return () => {
            isMounted.current = false;
        }
    }, [])

    const columnsTable: IColumnTable = [
        {
            name: 'Cliente',
            campo: 'name',
            classNameItem: "bg-left-blue-6"
        }, 
        {
            name: 'Telefone',
            campo: 'contact_principal',
            classNameHeader: 'txt-align-center',
            classNameItem: 'justify-center ',
        },
        {
            name: 'WhatsApp',
            campo: 'whatapp_principal',
            classNameItem: 'justify-center',
            classNameHeader: 'txt-align-center',
        },
        {
            name: 'Valor mensal',
            campo: 'value_month',
            classNameHeader: 'txt-align-center',
            classNameItem: 'justify-center',
        },
        {
            name: 'Opções',
            campo: 'options',
            classNameHeader: 'txt-align-center',
            componentDefault: (row: IClientConsultResponse) => {
                const onClickEdit = () => {
                    setidClient(row.id)
                    setIndexClient(listClients.indexOf(row));
                    setclientFormType('update');
                    setModalStatus(true);
                }

                const onClickDelete = () => {
                    let index = listClients.indexOf(row);
                    
                    Confirm(() => {
                        DeleteClient(row.id, response => {
                            Success('Apagado com sucesso')

                            let listClientsTemp = listClients;
                                                    
                            listClientsTemp.splice(index, 1)

                            setListClient([...listClientsTemp])
                        }, error => {
                            Error(error.data.message)
                        })
                    }, () => {})
                }

                const onClickStatus = () => {
                    let index = listClients.indexOf(row);
                    
                    Confirm(() => {
                        ToggleClient(row.id, row.status === "1" ? 'desative' : 'active', response => {
                            Success(row.status === "1" ? "Desativado com sucesso" : "Ativado com sucesso")

                            let listClientsTemp = listClients;
                                                    
                            listClientsTemp[index]['status'] = row.status === "1" ? "2" : "1"

                            setListClient([...listClientsTemp])
                        }, error => {
                            Error(error.data.message)
                        })
                    }, () => {}, 'Sim, continuar.')
                }

                return (
                    <div className="justify-center">
                        <span className="link-table-action bg-primary" onClick={onClickEdit}>
                            <i className="fas fa-edit"></i>
                            <p>Editar</p>
                        </span>
                        <span className="link-table-action bg-danger" onClick={onClickDelete}>
                            <i className="fas fa-trash-alt"></i>
                            <p>Apagar</p>
                        </span>
                        <span className={"link-table-action" + (row.status === "1" ? ' bg-warning' : ' bg-success')} onClick={onClickStatus}>
                            <i className="fas fa-toggle-off"></i>
                            <p>{row.status === "1" ? 'Desativar' : 'Ativar'}</p>
                        </span>
                    </div>
                )
            }
        },
    ]

    return (
        <div>
            <Sidebar/>
            <ModalContent show={ModalStatus} onClickClose={() => { setModalStatus(false) }}>
                <ClientForm typeRequest={clientFormType} id={idClient} onUpdate={response => {
                    let data_temp = listClients;
                    data_temp[indexClient] = response;
                    setListClient([...data_temp])
                    setModalStatus(false);
                }} onCreate={response => {
                    let data_temp = listClients
                    data_temp.unshift(response);
                    setListClient([...data_temp])
                    setModalStatus(false);
                }}/>
            </ModalContent>
            <div className="body__content">
                <h1 className="title__component">Clientes</h1>
                <div className="list-options-calendar">
                    <Button buttonConfig={{className: "bkg-blue bkg-blue-hover clr-white", onClick: () => {
                        setidClient("0")
                        setclientFormType("create")
                        setModalStatus(true)
                    }}}>
                        Criar cliente
                    </Button>
                </div>
                <Table columns={columnsTable} data={listClients} />
            </div>
        </div>
    );
}

export default Clientes;