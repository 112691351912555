import React, {useEffect, useState, useRef} from 'react';
import Sidebar from '../../../components/Sidebar';
import Button from '../../../components/Form/Button';
import Table, { IColumnTable } from '../../../components/Table';
import ConsultarGestors from '../../../../services/Admin/Manager/ConsultarGestores';
import ModalContent from '../../../components/ModalContent';
import ManagerForm from './components/ManagerForm';
import DeletarGestor from '../../../../services/Admin/Manager/DeletarGestor';
import Confirm from '../../../../util/Modals/Confirm';
import Success from '../../../../util/Modals/Success';
import AtualizarGestor from '../../../../services/Admin/Manager/AtualizarGestor';
import CriarGestor from '../../../../services/Admin/Manager/CriarGestor';

export interface IAdminGestoresProps {}

type IManagerUser = {
    id: string
    name: string
    email: string
}

const Gestores: React.FunctionComponent<IAdminGestoresProps> = (props) =>  {
    
    const isMounted = useRef(false)
    const [ModalStatus, setModalStatus] = useState(false)
    const [clientFormType, setclientFormType] = useState<'create' | 'update'>('create')
    const [listClients, setListClient]  = useState<Array<IManagerUser>>([])
    const [idClient, setidClient] = useState('0')
    const [indexClient, setIndexClient] = useState(0)

    useEffect(() => {
        isMounted.current = true

        ConsultarGestors(response => {
            if(isMounted.current)
            {
                setListClient(response.data.content)
            }
        }, error => Error('Não foi possível recuperar a lista de clientes'))
        return () => {
            isMounted.current = false;
        }
    }, [])

    const columnsTable: IColumnTable = [
        {
            name: 'Nome',
            campo: 'name',
            classNameItem: "bg-left-blue-6"
        }, 
        {
            name: 'E-mail',
            campo: 'email',
            classNameHeader: 'txt-align-center',
            classNameItem: 'justify-center ',
        },
        {
            name: 'Opções',
            campo: 'options',
            classNameHeader: 'txt-align-center',
            componentDefault: (row: IManagerUser) => {
                const onClickEdit = () => {
                    setidClient(row.id)
                    setIndexClient(listClients.indexOf(row));
                    setclientFormType('update');
                    setModalStatus(true);
                }

                const onClickDelete = () => {
                    let index = listClients.indexOf(row);
                    
                    Confirm(() => {
                        DeletarGestor(row.id, response => {
                            Success('Apagado com sucesso')

                            let listClientsTemp = listClients;
                                                    
                            listClientsTemp.splice(index, 1)

                            setListClient([...listClientsTemp])
                        }, error => {
                            Error(error.data.message)
                        })
                    }, () => {})
                }

                return (
                    <div className="justify-center">
                        <span className="link-table-action bg-primary" onClick={onClickEdit}>
                            <i className="fas fa-edit"></i>
                            <p>Editar</p>
                        </span>
                        <span className="link-table-action bg-danger" onClick={onClickDelete}>
                            <i className="fas fa-trash-alt"></i>
                            <p>Apagar</p>
                        </span>
                    </div>
                )
            }
        },
    ]

    return (
        <div>
            <Sidebar/>
            <ModalContent show={ModalStatus} onClickClose={() => setModalStatus(false)}>
                <ManagerForm 
                    typeRequest={clientFormType} 
                    id={idClient} 
                    onUpdate={form => {
                        AtualizarGestor(idClient, form, success => {
                            let data = listClients;

                            data[indexClient] = success.data.content;

                            setListClient([...data]);

                            Success('Status atualizado com successo')
                        }, error => {

                        })
                    }} 
                    onCreate={form => { 
                        CriarGestor(form, response => {
                            let data = listClients;

                            data.unshift(response.data.content);

                            setListClient([...data]);

                            Success('Status criado com successo')
                        }, error => {
                            Error(error.data.message)
                        })
                    }}/>
            </ModalContent>
            <div className="body__content">
                <h1 className="title__component">Gestores</h1>
                <div className="list-options-calendar">
                    <Button buttonConfig={{className: "bkg-blue bkg-blue-hover clr-white", onClick: () => {
                        setidClient("0")
                        setclientFormType("create")
                        setModalStatus(true)
                    }}}>
                        Criar gestor
                    </Button>
                </div>
                <Table columns={columnsTable} data={listClients} />
            </div>
            
        </div>
    );
}

export default Gestores;