import React from "react"

export interface IInputBox {
    label?: string,
    id?: string,
    configInput?: React.InputHTMLAttributes<HTMLInputElement>,
}

const Input = (props: IInputBox) => {
    return (
        <div className="input-box">
            <label htmlFor={props.id}>{props.label}</label>
            <input {...props.configInput} />
        </div>
    )
}

export default Input;