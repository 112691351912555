import { AxiosResponse } from 'axios';
import Request from '../../../util/Request';

export interface ICommentData {
    my_id: string,
    data: Array<ICommentDataArray>,
    clients: Array<ICommentsDataClientsArray>
}

export interface ICommentDataArray {
    id?: string,
    comment?: string,
    user_id?: string,
    client_id?: string,
    date_comment?: string,
    name?: string,
    user_name?: string,
    manager_id?: string
}

export interface ICommentsDataClientsArray {
    client_id?: string
    name?: string
}

const ConsultarComments = async (
    $successLogin: ((data: ICommentData) => void),
    $errorLogin: ((error: AxiosResponse) => void)
) => {
    await Request.get('/manager/clients/comments').then(
        response => {
            $successLogin(response.data.content)
        },
        $errorLogin
    )
}

export default ConsultarComments