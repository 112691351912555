import React from "react"

export interface IModalContentProps {
    show: boolean,
    onClickClose: (() => void)
}

const ModalContent: React.FunctionComponent<IModalContentProps> = (props) =>  {
    return (
        <div className={"modal-right" + (props.show ? ' show' : '')}>
            <div>
                <i className="fas fa-times fa-lg" onClick={props.onClickClose}></i>
            </div>
            <div className="modal-right-content">
                {props.children}
            </div>
        </div>  
    );
}

export default ModalContent;