import React, {useEffect, useState, useRef} from 'react';
import ListStatus, { IListStatusItem } from '../../../../../services/Admin/Status/ListStatus';
import Sidebar from '../../../../components/Sidebar';
import ModalContent from '../../../../components/ModalContent';
import StatusForm from '../components/StatusForm';
import CriarStatus from '../../../../../services/Admin/Status/CriarStatus';
import Success from '../../../../../util/Modals/Success';
import AtualizarStatus from '../../../../../services/Admin/Status/AtualizarStatus';
import Button from '../../../../components/Form/Button';
import TableDefault from '../components/TableDefault';
import Confirm from '../../../../../util/Modals/Confirm';
import DeletarStatus from '../../../../../services/Admin/Status/DeletarStatus';

export interface IStatusConteudoProps {}

const Conteudo: React.FunctionComponent<IStatusConteudoProps> = (props) =>  {
    const [listStatus, setListStatus] = useState<Array<IListStatusItem>>([])
    const isMounted = useRef(false)
    const [statusModel, setStatusModel] = useState(false)
    const [statusId, setStatusId] = useState("0")
    const [statusSelectedIndex, setStatusSelectedIndex] = useState(0)
    const [typeStatusForm, setTypeStatusForm] = useState<'create' | 'update'>("create")

    useEffect(() => {
        isMounted.current = true

        ListStatus('content', response => {
            if(isMounted.current)
            {
                setListStatus(response)
            }
        }, error => {
            Error('Houve um erro ao buscar os status')
        })
        return () => {
            isMounted.current = false
        }
    }, [])

    return (
        <div>
            <Sidebar/>
            <ModalContent show={statusModel} onClickClose={() => setStatusModel(false)}>
                <StatusForm 
                    status="content"
                    typeRequest={typeStatusForm} 
                    id={statusId} 
                    onCreate={form => {
                        CriarStatus(form, 'content', response => {
                            let data = listStatus;

                            data.unshift(response);

                            setListStatus([...data]);

                            Success('Status criado com successo')
                        }, error => {
                            Error(error.data.message)
                        })
                    }}
                    onUpdate={form => {
                        AtualizarStatus(statusId, form, 'content', success => {
                            let data = listStatus;

                            data[statusSelectedIndex] = success;

                            setListStatus([...data]);

                            Success('Status atualizado com successo')
                        }, error => {

                        })
                    }}
                />
            </ModalContent>
            <div className="body__content">
                <h1 className="title__component">Status: Conteúdo</h1>
                <div className="list-options-calendar">
                    <Button buttonConfig={{className: "bkg-blue bkg-blue-hover clr-white", onClick: () => {
                        setStatusId("0")
                        setTypeStatusForm("create")
                        setStatusModel(true)
                    }}}>
                        Criar status
                    </Button>
                </div>
                <TableDefault 
                    onClickDelete={row => {
                        let index = listStatus.indexOf(row)

                        Confirm(async () => {
                            await DeletarStatus(row.id, 'content', success => {
                                let data = listStatus;

                                data.splice(index, 1);

                                setListStatus([...data])

                                Success(success.data.message)
                            }, error => {
                                Error(error.data.message)
                            })
                        }, () => {})
                    }} 
                    onClickEdit={row => {
                        setStatusSelectedIndex(listStatus.indexOf(row))
                        setStatusModel(true)
                        setStatusId(row.id)
                        setTypeStatusForm("update")
                    }} 
                    data={listStatus}
                    />
            </div>
        </div>
    )
}

export default Conteudo;