import { IMidiaItem } from '../../ConsultMidiaList';
import { AxiosResponse } from 'axios'
import Request from '../../../util/Request'

const ConsultMidia = async (
    $id: string,
    success: ((response: Array<IMidiaItem>) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.get('/admin/clients/midias/'+$id).then(response => {
    success(response.data.content)
}).catch(error)

export default ConsultMidia