import { AxiosResponse } from 'axios'
import Request from '../../../../util/Request'

const CriarContent = async (
    data: {
        client_id: string,
        user_id: string,
        status: string,
        period_start: string,
        period_end: string,
        publishs: string,
        content_href: string,
        obs: string,
    },
    success: ((response: AxiosResponse) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.post(`manager/clients/content/social-media`, data).then(success).catch(error)


export default CriarContent