import React, {useEffect, useState, useRef} from 'react';
import SidebarManager from '../../../../../components/SidebarManager';
import ModalContent from '../../../../../components/ModalContent';
import { useParams } from 'react-router-dom';
import ContentForm from './components/ContentForm';
import Confirm from '../../../../../../util/Modals/Confirm';
import Table, { IColumnTable } from '../../../../../components/Table';
import ConsultarContents from '../../../../../../services/Manager/SocialMedia/Content/ConsultarContents';
import DeletarContent from '../../../../../../services/Manager/SocialMedia/Content/DeletarContent';
import Success from '../../../../../../util/Modals/Success';
import Button from '../../../../../components/Form/Button';
import CriarContent from '../../../../../../services/Manager/SocialMedia/Content/CriarContent';
import AtualizarStatus from '../../../../../../services/Manager/SocialMedia/Clients/AtualizarStatus';
import NPSForm from './components/NPSForm';
import AtualizarNPSSocialMedia from '../../../../../../services/Manager/SocialMedia/NPS/AtualizarNPSSocialMedia';
import ReportMonth from './components/ReportMonth';
import CreateReportMonth from '../../../../../../services/ReportMonth/CreateReportMonth';
import Error from '../../../../../../util/Modals/Error';

export interface IGestorConteudoProps {}

const ConteudoCliente: React.FunctionComponent<IGestorConteudoProps> = (props) =>  {

    const isMounted = useRef(false)
    const [ModalStatus, setModalStatus] = useState(false)
    const [clientFormType, setclientFormType] = useState<'create' | 'update'>('create')
    const [listClients, setListClient]  = useState<Array<any>>([])
    const [idClient, setidClient] = useState('0')
    const [indexClient, setIndexClient] = useState(0)
    const { id, client_id } = useParams<any>();

    useEffect(() => {
        isMounted.current = true

        ConsultarContents(id, client_id, response => {
            if(isMounted.current)
            {
                setListClient(response.data.content)
            }
        }, error => Error('Não foi possível recuperar a lista de clientes'))
        
        return () => {
            isMounted.current = false;
        }
    }, [])

    const columnsTable: IColumnTable = [
        {
            name: 'Nome',
            campo: 'name',
            classNameItem: "bg-left-blue-6",
        }, 
        {
            name: 'Início/Termíno',
            classNameHeader: 'txt-align-center',
            campo: 'period_start',
            componentDefault: (row: any) => {
                return (
                    <div className="justify-center">
                        {row.period_start} - {row.period_end}
                    </div>
                )
            }
        }, 
        {
            name: 'Publicações',
            classNameHeader: 'txt-align-center',
            classNameItem: 'justify-center',
            campo: 'publishs',
        }, 
        {
            name: 'Anexos',
            campo: 'content_href',
            classNameHeader: 'txt-align-center',
            componentDefault: (row: any) => {
                return (
                    <div className="justify-center">
                        <a href={row.content_href}>Clique aqui</a>
                    </div>
                )
            } 
        }, 
        {
            name: 'Status',
            campo: 'status',
            classNameHeader: 'txt-align-center',
            componentDefault: (row: any) => {
                return (
                    <div className="justify-center" style={{backgroundColor: row.color, color: "#fff"}}>
                        {row.label}
                    </div>
                )
            } 
        }, 
        {
            name: 'Opções',
            campo: 'options',
            classNameHeader: 'txt-align-center',
            componentDefault: (row: any) => {
                const onClickEdit = () => {
                    setidClient(row.id)
                    setIndexClient(listClients.indexOf(row));
                    setclientFormType('update');
                    setModalStatus(true);
                }

                const onClickDelete = () => {
                    let index = listClients.indexOf(row);
                    
                    Confirm(() => {
                        DeletarContent(row.id, response => {
                            Success('Apagado com sucesso')

                            let listClientsTemp = listClients;
                                                    
                            listClientsTemp.splice(index, 1)

                            setListClient([...listClientsTemp])
                        }, error => {
                            Error(error.data.message)
                        })
                    }, () => {})
                }

                return (
                    <div className="justify-center">
                        <span className="link-table-action bg-primary" onClick={onClickEdit}>
                            <i className="fas fa-edit"></i>
                            <p>Editar</p>
                        </span>
                        <span className="link-table-action bg-danger" onClick={onClickDelete}>
                            <i className="fas fa-trash-alt"></i>
                            <p>Apagar</p>
                        </span>
                    </div>
                )
            }
        },
    ]

    const [ModalStatusNPS, setModalStatusNPS] = useState(false)
    const [ModalReportMonth, setModalReportMonth] = useState(false)
    
    return (
        <div>
            <SidebarManager/>
            <ModalContent show={ModalStatus} onClickClose={() => setModalStatus(false)}>
                <ContentForm 
                    id={idClient} 
                    typeRequest={clientFormType} 
                    onUpdate={form => {
                        AtualizarStatus(idClient, form, success => {
                            Success('Criado com sucesso', () => {
                                window.location.reload()
                            })
                        }, error => {
                            Error(error.data.message ?? 'Erro ao criar')
                        })
                    }} 
                    onCreate={form => {
                        CriarContent(form, success => {
                            Success('Criado com sucesso', () => {
                                window.location.reload()
                            })
                        }, error => {
                            Error(error.data.message ?? 'Erro ao criar')
                        })
                    }} 
                    />
            </ModalContent>
            <ModalContent 
                show={ModalStatusNPS} 
                onClickClose={() => setModalStatusNPS(false)}>
                <NPSForm 
                    onUpdate={form => {
                        AtualizarNPSSocialMedia(id, client_id, form, () => {
                            Success('NPS Atualizado com sucesso!')
                        }, error => {
                            Error('Não foi possível atualizar o NPS')
                        })
                    }} />
            </ModalContent>
            <ModalContent 
                show={ModalReportMonth} 
                onClickClose={() => setModalReportMonth(false)}>
                <ReportMonth 
                    clientId={client_id}
                    onCreate={form => {
                        CreateReportMonth(form, () => {
                            Success('Relatório criado com sucesso', () => setModalReportMonth(false))
                        }, () => Error('Houve um erro ao tentar criar este relatório'))
                    }} />
            </ModalContent>
            <div className="body__content">
                <h1 className="title__component">Conteúdo e NPS do Cliente</h1>
                <div className="list-options-calendar">
                    <Button buttonConfig={{className: "bkg-blue bkg-blue-hover clr-white", onClick: () => {
                        setidClient("0")
                        setclientFormType("create")
                        setModalStatus(true)
                    }}}>
                        Adicionar conteúdo
                    </Button>
                    <Button buttonConfig={{style: {marginLeft: '10px'},className: "bkg-blue-green bkg-blue-green-hover clr-white", onClick: () => {
                        setModalStatusNPS(true)
                    }}}>
                        Revisar NPS
                    </Button>
                    <Button buttonConfig={{style: {marginLeft: '10px'},className: "bkg-green bkg-green-hover clr-white", onClick: () => {
                        setModalReportMonth(true)
                    }}}>
                        Adicionar relatório 
                    </Button>
                </div>
                <Table columns={columnsTable} data={listClients} />
            </div>
        </div>
    );
}

export default ConteudoCliente;