import React from "react"
import Cookies from "universal-cookie"
import {
  BrowserRouter as RouterDom,
  RouteProps,
  Redirect,
  Route,
} from "react-router-dom"

const cookies = new Cookies()

const RouteSignUp = (props: RouteProps) => 
{
    cookies.remove('X-Request-Token')
    cookies.remove('X-Request-Type')

    return <Route {...props} component={() => <Redirect to="/entrar" />} />
}

export default RouteSignUp;