import { AxiosResponse } from 'axios';
import Request from '../../../util/Request';


export interface ICommentData {
    id: string
    comment: string
    client_id: string
    user_id: string
    date_comment: string
}

const ConsultarComment = async (
    id: string,
    $successLogin: ((data: ICommentData) => void),
    $errorLogin: ((error: AxiosResponse) => void)
) => {
    await Request.get(`/manager/clients/comments/${id}`).then(
        response => {
            $successLogin(response.data.content)
        },
        $errorLogin
    )
}

export default ConsultarComment