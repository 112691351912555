import { AxiosResponse } from 'axios'
import Request from '../../../../util/Request'

const ConsultarNPSSocialMedia = async (
    user_id: string,
    client_id: string,
    success: ((response: AxiosResponse) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.get(`manager/clients/social-media/nps/${user_id}/${client_id}`).then(success).catch(error)


export default ConsultarNPSSocialMedia