import { AxiosResponse } from 'axios'
import Request from '../../util/Request'

const AtualizarMinhaSenha = async (
    $form: {
        new_password: string,
        last_password: string
    },
    success: ((response: AxiosResponse) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.post('/manager/alter-password', $form).then(success).catch(error)

export default AtualizarMinhaSenha