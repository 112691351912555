import { AxiosResponse } from 'axios'
import Request from '../../../util/Request'

const AtualizarSocialMedia = async (
    id: string,
    data: any,
    success: ((response: AxiosResponse) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.put(`/manager/social-media/${id}`, data).then(success).catch(error)


export default AtualizarSocialMedia