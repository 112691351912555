import { AxiosResponse } from 'axios'
import Request from '../../../util/Request'

const CriarGestor = async (
    $form: {
      email: string,
      password: string,
      name: string  
    },
    success: ((response: AxiosResponse) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.post(`/admin/manager`, $form).then(success).catch(error)


export default CriarGestor