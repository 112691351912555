import { AxiosResponse } from 'axios'
import Request from '../../../util/Request'

const AtualizarGestor = async (
    $id: string,
    $form: {
      email: string,
      name: string  
    },
    success: ((response: AxiosResponse) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.put(`/admin/manager/${$id}`, $form).then(success).catch(error)


export default AtualizarGestor