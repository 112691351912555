import { AxiosResponse } from 'axios'
import Request from '../../../../util/Request'

const AtualizarNPSSocialMedia = async (
    user_id: string,
    client_id: string,
    data: {
        data?: string,
        starts?: string,
        feedback?: string,
    },
    success: ((response: AxiosResponse) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.put(`manager/clients/social-media/nps/${user_id}/${client_id}`, data).then(success).catch(error)


export default AtualizarNPSSocialMedia