import { AxiosResponse } from 'axios'
import Request from '../../../../util/Request'

const AtualizarStatus = async (
    id: string,
    data: {
        status?: string
        period_start?: string
        period_end?: string
        publishs?: string
        content_href?: string
        obs?: string
    },
    success: ((response: AxiosResponse) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.put(`/manager/clients/social-media/${id}`, data).then(success).catch(error)


export default AtualizarStatus