import { AxiosResponse } from 'axios'
import Request from '../../../util/Request'
import { IListStatusItem } from './ListStatus'

const DeletarStatus = async (
    $id: string,
    $status: "calendar" | "content" | "finance" | "report-simple",
    success: ((response: AxiosResponse) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.delete('admin/status/'+ $status + '/' + $id).then(success).catch(error)

export default DeletarStatus