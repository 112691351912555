import React, {useEffect, useState, useRef} from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/Form/Button';
import Table, { IColumnTable } from '../../../components/Table';
import ConsultarSocialMedias from '../../../../services/Manager/SocialMedia/ConsultarSocialMedias';
import Confirm from '../../../../util/Modals/Confirm';
import DeletarSocialMedia from '../../../../services/Manager/SocialMedia/DeletarSocialMedia';
import Success from '../../../../util/Modals/Success';
import Error from '../../../../util/Modals/Error';
import ModalContent from '../../../components/ModalContent';
import SocialMediaForm from './components/SocialMediaForm';
import AtualizarSocialMedia from '../../../../services/Manager/SocialMedia/AtualizarSocialMedia';
import CriarSocialMedia from '../../../../services/Manager/SocialMedia/CriarSocialMedia';
import SidebarManager from '../../../components/SidebarManager';

export interface IGestorSocialMediaProps {}

const SocialMedia: React.FunctionComponent<IGestorSocialMediaProps> = (props) =>  {

    const isMounted = useRef(false)
    const [ModalStatus, setModalStatus] = useState(false)
    const [clientFormType, setclientFormType] = useState<'create' | 'update'>('create')
    const [listClients, setListClient]  = useState<Array<any>>([])
    const [idClient, setidClient] = useState('0')
    const [indexClient, setIndexClient] = useState(0)
    const router = useHistory()

    useEffect(() => {
        isMounted.current = true

        ConsultarSocialMedias(response => {
            if(isMounted.current)
            {
                setListClient(response.data.content)
            }
        }, error => Error('Não foi possível recuperar a lista de clientes'))
        
        return () => {
            isMounted.current = false;
        }
    }, [])

    const columnsTable: IColumnTable = [
        {
            name: 'Nome',
            campo: 'name',
            classNameItem: "bg-left-blue-6",
            componentDefault: (row: any) => {
                return (
                    <div className="bg-left-blue-6">
                        <span className="link-text-span" onClick={() => {
                            router.push(`/manager/social-media/${row.id}/clients`)
                        }}>{row.name}</span>
                    </div>
                )
            }
        }, 
        {
            name: 'E-mail',
            campo: 'email',
            classNameHeader: 'txt-align-center',
            classNameItem: 'justify-center ',
        },
        {
            name: 'Opções',
            campo: 'options',
            classNameHeader: 'txt-align-center',
            componentDefault: (row: any) => {
                const onClickEdit = () => {
                    setidClient(row.id)
                    setIndexClient(listClients.indexOf(row));
                    setclientFormType('update');
                    setModalStatus(true);
                }

                const onClickDelete = () => {
                    let index = listClients.indexOf(row);
                    
                    Confirm(() => {
                        DeletarSocialMedia(row.id, response => {
                            Success('Apagado com sucesso')

                            let listClientsTemp = listClients;
                                                    
                            listClientsTemp.splice(index, 1)

                            setListClient([...listClientsTemp])
                        }, error => {
                            Error(error.data.message)
                        })
                    }, () => {})
                }

                return (
                    <div className="justify-center">
                        <span className="link-table-action bg-primary" onClick={onClickEdit}>
                            <i className="fas fa-edit"></i>
                            <p>Editar</p>
                        </span>
                        <span className="link-table-action bg-danger" onClick={onClickDelete}>
                            <i className="fas fa-trash-alt"></i>
                            <p>Apagar</p>
                        </span>
                    </div>
                )
            }
        },
    ]

    
    return (
        <div>
            <SidebarManager/>
            <ModalContent 
                show={ModalStatus} 
                onClickClose={() => setModalStatus(false)}>
                <SocialMediaForm 
                    onCreate={data => {
                        CriarSocialMedia(
                            data,
                            success => {
                                Success('Criado com sucesso!', () => {
                                    let data = listClients;

                                    data.unshift(success.data.content);

                                    setListClient([...data]);
                                })
                            }, error => {
                                Error(error.data.message ?? 'Houve um erro ao atualizar este Social Media')
                            }
                        )
                    }} 
                    onUpdate={data => {
                        AtualizarSocialMedia(
                            data.id,
                            data,
                            success => {
                                Success('Atualizado com sucesso!', () => {
                                    let data = listClients;

                                    data[indexClient] = success.data.content;

                                    setListClient([...data]);
                                })
                            }, error => {
                                Error(error.data.message ?? 'Houve um erro ao atualizar este Social Media')
                            }
                        )
                    }} 
                    id={idClient} 
                    typeRequest={clientFormType} />
            </ModalContent>
            <div className="body__content">
                <h1 className="title__component">Social Medias</h1>
                    <div className="list-options-calendar">
                        <Button buttonConfig={{className: "bkg-blue bkg-blue-hover clr-white", onClick: () => {
                            setidClient("0")
                            setclientFormType("create")
                            setModalStatus(true)
                        }}}>
                            Criar Social Media
                        </Button>
                    </div>
                    <Table columns={columnsTable} data={listClients} />
            </div>
        <div/>
        </div>
    )
}

export default SocialMedia;