import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import ConsultReportMonthById from '../../../../../services/ReportMonth/ConsultReportMonthById'
import Button from '../../../../components/Form/Button'
import Input from '../../../../components/Form/Input'

type formStatus = {
    link: string,
    date_report: string,
    id: string
}

export interface IUpdateReport {
    onUpdate: ((response: formStatus) => void),
    reportId: string
}

const UpdateReport: React.FunctionComponent<IUpdateReport> = (props) =>  {

    const defaultSocialMedia: formStatus = {
        link: '',
        date_report: '',
        id: props.reportId
    }

    const [statusSelectForm, setstatusSelectForm] = useState<formStatus>(defaultSocialMedia)
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true

        if(props.reportId)
        {
            ConsultReportMonthById(props.reportId, e => {
                setstatusSelectForm({
                    ...statusSelectForm,
                    link: e.data.content.link,
                    date_report: e.data.content.date_report
                })
            }, () => {})
        }

        return () => {
            isMounted.current = false
        };
    }, [props.reportId])

    const onSubmitEvent = async (event: React.ChangeEvent<HTMLFormElement>) => {

        event.preventDefault()

        props.onUpdate(statusSelectForm)
    }

    return (
        <form method="POST" onSubmit={onSubmitEvent}>
            <Input 
                configInput={{
                    type: "text",
                    className: "w-100",
                    value: statusSelectForm.link,
                    onChange: event => {
                        setstatusSelectForm(
                            {
                                ...statusSelectForm,
                                link: event.target.value
                            }
                        )
                    }
                }}
                label="Link relatório" />
            <Input 
                configInput={{
                    type: "date",
                    className: "w-100",
                    value: statusSelectForm.date_report,
                    onChange: event => {
                        setstatusSelectForm(
                            {
                                ...statusSelectForm,
                                date_report: event.target.value
                            }
                        )
                    }
                }}
                label="Data" />
            <Button 
                buttonConfig={
                    {
                        className:"bg-primary clr-white"
                    }
                }
                >
                    Atualizar
            </Button>
        </form>
    );
}

export default UpdateReport