import Modal from "../Modal"

const Warning = ($text: string) => {
    Modal.fire({
        title: 'Aviso!',
        text: $text,
        icon: 'warning',
    })
}


export default Warning