import React from "react"
import {
  BrowserRouter as RouterDom,
  Switch,
  Route,
} from "react-router-dom"

import RouteSignUp from "./RouteSignUp"
import EntrarGestor from "../pages/EntrarGestor"
import EsqueciSenha from "../pages/EsqueciSenha"
import RouteProtect from "./RouteProtect"
import EntrarAdmin from "../pages/EntrarAdmin"
import InicioRouter from "../pages/app/InicioRouter"
import Clientes from "../pages/app/Admin/Clientes"

import { Calendario, RelatorioSimplificado, Financeiro, Conteudo } from "../pages/app/Admin/Status"
import Gestores from "../pages/app/Admin/Gestores"
import MinhaConta from "../pages/app/Admin/MinhaConta"
import AccountManager from "../pages/app/Gestor/Account"
import SocialMedia from "../pages/app/Gestor/SocialMedia"
import Clients from "../pages/app/Gestor/SocialMedia/Clients"
import ConteudoCliente from "../pages/app/Gestor/SocialMedia/Clients/ConteudoCliente"
import RelatorioRapido from "../pages/app/Gestor/RelatorioRapido"
import RelatorioMensal from "../pages/app/Gestor/RelatorioMensal"
import Observacoes from "../pages/app/Gestor/Observacoes"
import RelatorioRapidoNPS from "../pages/app/Gestor/RelatorioRapidoNPS"

const Router = () => {
    return (
        <RouterDom>
            <Switch>
                <RouteProtect type="admin" path="/admin/status/content" exact component={Conteudo} />
                <RouteProtect type="admin" path="/admin/status/calendar" exact component={Calendario} />
                <RouteProtect type="admin" path="/admin/status/finance" exact component={Financeiro}/>
                <RouteProtect type="admin" path="/admin/status/report-simple" exact component={RelatorioSimplificado}/>
                <RouteProtect type="admin" path="/admin/clientes" exact component={Clientes} />
                <RouteProtect type="admin" path="/admin/minha-conta" exact component={MinhaConta} />
                <RouteProtect type="admin" path="/admin/manager" exact component={Gestores} />
                
                <RouteProtect type="manager" path="/manager/minha-conta" exact component={AccountManager} />
                <RouteProtect type="manager" path="/manager/social-media" exact component={SocialMedia} />
                <RouteProtect type="manager" path="/manager/social-media/:id/clients" exact component={Clients} />
                <RouteProtect type="manager" path="/manager/social-media/:id/clients/:client_id" exact component={ConteudoCliente} />
                <RouteProtect type="manager" path="/manager/relatorio-rapido" exact component={RelatorioRapido} />
                <RouteProtect type="manager" path="/manager/relatorio-nps" exact component={RelatorioRapidoNPS} />
                <RouteProtect type="manager" path="/manager/relatorio-mensal/:client_id?" exact component={RelatorioMensal} />
                <RouteProtect type="manager" path="/manager/observacoes/:client_id?" exact component={Observacoes} />

                <RouteProtect path="/" exact component={InicioRouter} />
                <Route path="/esqueceu-senha" component={EsqueciSenha} exact />
                <Route path="/entrar" component={EntrarGestor} exact />
                <Route path="/entrar-admin" component={EntrarAdmin} exact />
                <RouteSignUp path="/sair" exact />
            </Switch>
        </RouterDom>
    )
}


export default Router;