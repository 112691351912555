import { IClientConsultResponse } from './index'
import { AxiosResponse } from 'axios'
import Request from '../../../util/Request'

const UpdateMidias = async (
    id: string,
    $form: any,
    success: ((response: AxiosResponse) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.put(`/admin/clients/client/${id}/midia`, $form).then(response => {
    success(response.data.content)
}).catch(error)

export default UpdateMidias