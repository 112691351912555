import { IClientConsultResponse } from './index'
import { AxiosResponse } from 'axios'
import Request from '../../../util/Request'

const ToggleClient = async (
    $id: string,
    $status: "active" | "desative",
    success: ((response: AxiosResponse) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.put('/admin/clients/client/'+$id+'/'+$status).then(success).catch(error)

export default ToggleClient