import React from 'react';
import Success from '../../../../util/Modals/Success';
import { IMeuCliente } from '../../../../services/ConsultClient';

export interface IClientDetailsProps extends IMeuCliente {}

const ClientDetails: React.FunctionComponent<IClientDetailsProps> = (props: IMeuCliente) =>  {
    const copyItem = ($content: string) => {
        /* Get the text field */
        // let copyText:any = event.target;
        var textField = document.createElement('textarea')
        textField.innerText = $content
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()

        Success('A informação foi copiada.')
    }
    
    return (
        <div>
            <p className="p__title__detail">Nome do escritório (fantasia):</p>
            <p className="p__content__detail" onClick={event => copyItem(props.name)}>{props.name}</p>
            <p className="p__title__detail">Contato principal:</p>
            <p className="p__content__detail" onClick={event => copyItem(props.contact_principal)}>{props.contact_principal}</p>
            <p className="p__title__detail">E-mail do contato principal:</p>
            <p className="p__content__detail" onClick={event => copyItem(props.email_principal)}>{props.email_principal}</p>
            <p className="p__title__detail">Whatsapp do contato principal:</p>
            <p className="p__content__detail" onClick={event => copyItem(props.whatapp_principal)}>{props.whatapp_principal}</p>
            <p className="p__title__detail">Cidade (sede do cliente):</p>
            <p className="p__content__detail" onClick={event => copyItem(props.city)}>{props.city}</p>
            <p className="p__title__detail">Site do cliente:</p>
            <p className="p__content__detail" onClick={event => copyItem(props.website)}>{props.website}</p>
            <p className="p__title__detail">Briefing do cliente:</p>
            <p className="p__content__detail" onClick={event => copyItem(props.briefing_client)}>{props.briefing_client}</p>
            <p className="p__title__detail">Ações contratadas (mídias):</p>
            <p className="p__content__detail" onClick={event => copyItem(props.actions_contract)}>{props.actions_contract}</p>
            <p className="p__title__detail">Valor do plano mensal:</p>
            <p className="p__content__detail" onClick={event => copyItem(props.value_month)}>{props.value_month}</p>
            <p className="p__title__detail">Drive do cliente:</p>
            <p className="p__content__detail" onClick={event => copyItem(props.drive_href)}><a href="!#">{props.drive_href}</a></p>
            {/* <p className="p__title__detail">Mídias - status e acessos:</p> */}
            {/* <p className="p__content__detail" onClick={event => copyItem(props.}</)}>{props.}</p> */}
            <p className="p__title__detail">Padrão de layout:</p>
            <p className="p__content__detail" onClick={event => copyItem(props.layout_default)}>{props.layout_default}</p>
            <p className="p__title__detail">Sobre o plano de ação:</p>
            <p className="p__content__detail" onClick={event => copyItem(props.about_plain)}>{props.about_plain}</p>
            <p className="p__title__detail">Responsável pelo Onboarding:</p>
            <p className="p__content__detail" onClick={event => copyItem(props.user_onboarding)}>{props.user_onboarding}</p>
            <p className="p__title__detail">Observações sobre o cliente:</p>
            <p className="p__content__detail" onClick={event => copyItem(props.about_plain)}>{props.about_plain}</p>
        </div>
    );
}

export default ClientDetails;