import React, {useEffect} from 'react';
import { LogoWhite } from '../../../assets';
import { Link } from 'react-router-dom';

export interface ISidebarProps {}

const Sidebar: React.FunctionComponent<ISidebarProps> = (props) =>  {

    const showMenu = (toggleId: string, navbarId: string, bodyId: string) => 
    {
        const toggle = document.getElementById(toggleId),
        navbar = document.getElementById(navbarId),
        bodypadding = document.getElementById(bodyId)
      
        if(toggle && navbar){
          toggle.addEventListener('click', ()=>{
            navbar.classList.toggle('expander')
      
            bodypadding?.classList.toggle('body-pd')
          })
        }
    }

    useEffect(() => {
        showMenu('nav-toggle','navbar','body-pd')

        const linkCollapse = document.getElementsByClassName('collapse__link')
      
        for(let i = 0; i < linkCollapse.length; i++)
        {
            let next_link:any = linkCollapse[i];

            linkCollapse[i].addEventListener('click', () => 
                {
                    const collapseMenu = next_link.nextElementSibling
                    collapseMenu.classList.toggle('showCollapse')
                
                    const rotate = collapseMenu.previousElementSibling
                    rotate.classList.toggle('rotate')
                }
            )
        }
    }, [])
      
    return (
        <div className="l-navbar" id="navbar">
            <nav className="nav">
                <div>
                    <div className="nav__brand">
                        <i className="fas fa-bars nav__toggle" id="nav-toggle"></i>
                        <Link to="#" style={{marginLeft: '20px'}}><img src={LogoWhite} alt="" width="100px" /></Link>
                    </div>
                    <div className="nav__list">
                        <a href="/" className="nav__link">
                            <i className="fas fa-home "></i>
                            <span className="nav__name">Início</span>
                        </a>
                        <a href="/admin/minha-conta" className="nav__link">
                            <i className="far fa-user-circle"></i>
                            <span className="nav__name">Minha conta</span>
                        </a>
                        <Link to="/admin/clientes" className="nav__link collapse">
                            <i className="fas fa-users"></i>
                            <span className="nav__name">Clientes</span>
                        </Link>
                        <Link to="/admin/manager" className="nav__link collapse">
                            <i className="fas fa-user-tie"></i>
                            <span className="nav__name">Gestores</span>
                        </Link>
                        <span className="nav__link collapse">
                            <i className="fas fa-tags"></i>
                            <span className="nav__name">Status</span>

                            <i className="fas fa-angle-down collapse__link"></i>

                            <ul className="collapse__menu">
                                <Link to="/admin/status/finance" className="collapse__sublink">Financeiro</Link>
                                <Link to="/admin/status/calendar" className="collapse__sublink">Calendário</Link>
                                <Link to="/admin/status/content" className="collapse__sublink">Conteúdo</Link>
                                <Link to="/admin/status/report-simple" className="collapse__sublink">Relatório Simples</Link>
                            </ul>
                        </span>
                        <Link to="/sair" className="nav__link">
                            <i className="fas fa-sign-out-alt"></i>
                            <span className="nav__name">Sair</span>
                        </Link>
                    </div>
                </div>
            </nav>
        </div>  
    );
}

export default Sidebar;