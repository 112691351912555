import React, {useEffect, useState} from 'react';
import { Link, Route, Switch, useParams } from 'react-router-dom';
import ConsultarComments, { ICommentDataArray, ICommentsDataClientsArray } from '../../../../services/Manager/Comment/ConsultarComments';
import Warning from '../../../../util/Modals/Warning';
import Button from '../../../components/Form/Button';
import SidebarManager from '../../../components/SidebarManager';
import Table, { IColumnTable } from '../../../components/Table';
import AtualizarComentario from './components/AtualizarComentario';
import CriarComentario from './components/CriarComentario';

export interface IObservacoesProps {}

const Observacoes: React.FunctionComponent<IObservacoesProps> = (props) =>  
{
    const columnsTable: IColumnTable = [
        {
            name: 'Cliente',
            campo: 'name',
            classNameHeader: 'txt-align-left',
            componentDefault: (row: any) => (
                <div>
                    <Link to={`/manager/observacoes/${row.client_id}`}>{row.name}</Link>
                </div>
            )
        }
    ]

    const [commentsData, setCommentsData] = useState<Array<ICommentDataArray>>([])
    const [clientsData, setClientsData] = useState<Array<ICommentsDataClientsArray>>([])
    const [myId, setMyId] = useState('')

    useEffect(() => {
        ConsultarComments(data => {
            setCommentsData(data.data)
            setMyId(data.my_id)
            setClientsData([...data.clients])
        }, error => {
            Warning('Houve um erro ao buscar seus dados.')
        })
    }, [])


    const { client_id } = useParams<{client_id: string}>()

    const [atualizarComentarioStatus, setAtualizarComentarioStatus] = useState(false)
    const [idComment, setIdComment] = useState<any>('')
    const [criarComentarioModalStatus, setCriarComentarioModalStatus] = useState(false)

    return (
        <div>
            <SidebarManager/>
            <div className="body__content">
                <AtualizarComentario idComment={idComment} statusView={atualizarComentarioStatus} onClose={() => setAtualizarComentarioStatus(false)} />
                <CriarComentario statusView={criarComentarioModalStatus} onClose={() => setCriarComentarioModalStatus(false)} />
                <h1 className="title__component"><i className="far fa-comment-alt clr-blue"></i> Observações</h1>
                <Switch>
                    <Route exact path="/manager/observacoes/:client_id">
                    <div className="list-options-calendar">
                            <Button buttonConfig={{className: "bkg-blue bkg-blue-hover clr-white", onClick: () => {
                                // set("0")
                                // setclientFormType("create")
                                setCriarComentarioModalStatus(true)
                            }}}>
                                Criar observação
                            </Button>
                        </div>
                        <div className="comments-box">
                            {
                                commentsData.map(e => {
                                    if(e.client_id === client_id)
                                    {
                                        return (
                                            <div className="box-obs">
                                                <p className="client-name clr-blue">
                                                    {e.name}
                                                </p>
                                                <p className="comment">{e.comment}</p>
                                                {
                                                    myId === e.manager_id ? 
                                                    (
                                                        <div className="comment-edit" onClick={event => {
                                                            setIdComment(e.id)
                                                            setAtualizarComentarioStatus(true)
                                                        }}>Editar</div>
                                                    ) : null
                                                }
                                                <div className="comment-by">Feita por <span>{e.user_name}</span></div>
                                            </div>
                                        )
                                    }else{
                                        return null;
                                    }
                                })
                            }
                        </div>
                    </Route>
                    <Route exact path="">
                        <br/><br/>
                        <p>Selecione um cliente</p>
                        <br/>
                        <Table withoutFooter={true} data={clientsData} columns={columnsTable}/>
                    </Route>
                </Switch>
            </div>
        </div>
    );
}

export default Observacoes;