import React, {useState, useEffect} from 'react';
import { LogoComum } from '../assets';
import Input from './components/Form/Input';
import Button from './components/Form/Button';
import FazerLogin from '../services/FazerLogin';
import Warning from '../util/Modals/Warning';
import { setSession, closeSession } from '../util/UserSession';
import { useHistory } from 'react-router-dom';

export interface IEntrarAdminProps {}

const EntrarAdmin: React.FunctionComponent<IEntrarAdminProps> = (props) =>  {

    const [formUser, setFormUser] = useState({
        email: '',
        password: ''
    })


    const router = useHistory()

    useEffect(() => {
        closeSession()
      }, [])

    const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => 
    {
        event.preventDefault()

        await FazerLogin('admin', formUser, 
            response => {
                setSession(
                    response.content.token, 
                    response.content.type
                )

                router.push('/')
            }, error => {
                Warning(error.data.message)
            }
        )
    }

    return (
      <div className="full-screen">
        <form method="POST" className="form-login" onSubmit={handleSubmit}>
            <div className="logo-icon">
                <img src={ LogoComum } alt=""/>
            </div>
            <Input 
                label="E-mail" 
                configInput={{
                    type: 'text',
                    placeholder: 'Digite seu e-mail',
                    value: formUser.email,
                    onChange: event => {
                        setFormUser({
                            ...formUser,
                            email: event.target.value
                        })
                    }
                }}
            /> 
            <Input 
                label="Senha" 
                configInput={{
                    type: 'password',
                    placeholder: '***',
                    value: formUser.password,
                    onChange: event => {
                        setFormUser({
                            ...formUser,
                            password: event.target.value
                        })
                    }
                }}
            /> 
            <div className="links">
                <a href="/esqueceu-senha">Esqueceu sua senha?</a>
            </div>
            <Button buttonConfig={{className: "clr-white bkg-black bkg-black-hover"}}>
                Entrar
            </Button>
        </form>
      </div>
    );
}

export default EntrarAdmin;