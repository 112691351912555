import { IClientConsultResponse } from './index'
import { AxiosResponse } from 'axios'
import Request from '../../../util/Request'

const DeleteClient = async (
    $id: string,
    success: ((response: AxiosResponse) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.delete('/admin/clients/client/'+$id).then(success).catch(error)

export default DeleteClient