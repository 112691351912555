import { AxiosResponse } from 'axios'
import Request from '../../../util/Request'

export interface IClientConsultResponse {
    id: string
    name: string
    contact_principal: string
    email_principal: string
    whatapp_principal: string
    city: string
    website: string
    briefing_client: string
    actions_contract: string
    value_month: string
    publish_month: string
    drive_href: string
    layout_default: string
    about_plain: string
    user_onboarding: string
    about_client: string
    midias?: string
    status?: string
}

const ClientConsult = async (
    success: ((response: Array<IClientConsultResponse>) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.get('/admin/clients').then(
    response => {
        success(response.data.content)
    }
).catch(error)


export { ClientConsult }