import React from 'react'

export interface IButton extends React.Props<React.FC> {
    buttonConfig?: React.ButtonHTMLAttributes<HTMLButtonElement>,
}

const Button = (props: IButton) => {
    return (
        <div className="button-click">
            <button {...props.buttonConfig}>{props.children}</button>
        </div>
    )
}

export default Button;