import { AxiosResponse } from 'axios'
import Request from '../../util/Request'

export interface ReportMonthRequestData {
    data: Array<ReportMonthData>,
    clients: Array<ReportMonthClient>
}

export interface ReportMonthData {
    id?: string
    client_id?: string
    link?: string
    date_report?: string
    name?: string
}

export interface ReportMonthClient {
    client_id: string
    name: string
}

const ConsultAllReportMonth = async (
    success: ((response: ReportMonthRequestData) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.get('/manager/clients/report-month').then(response => {
    success(response.data.content)
}).catch(error)

export default ConsultAllReportMonth