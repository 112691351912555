import React, {useEffect, useState} from 'react';
import ModalContent from '../ModalContent';
import ClientDetails from './components/ClientDetails';
import ConsultClient from '../../../services/ConsultClient';

export interface IClientModalProps {
    statusView: boolean,
    clientId: string,
    typeUser: 'admin' | 'manager'
    onCloseModal: (() => void)
}

const ClientModal: React.FunctionComponent<IClientModalProps> = (props) =>  {

    const isMounted = React.useRef(false)
    const [clientSelect, setclientSelect] = useState<any>({})


    useEffect(() => {
        isMounted.current = true;

        if(props.clientId !== "0")
        {
            ConsultClient(
                props.clientId, 
                props.typeUser,
                data => {
                    if(isMounted.current)
                    {
                        setclientSelect(data);
                    }
                }, 
                err => {
                    console.log(err)
                    // ModalError(err.data.message)
                }
            )
        }
    }, [props.clientId])

    return (
        <ModalContent show={props.statusView} onClickClose={props.onCloseModal}>
            <ClientDetails {...clientSelect} />
        </ModalContent>
    );
}

export default ClientModal;