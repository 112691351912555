import React from 'react'
import { getSession } from '../../../util/UserSession'

import Inicio from '../Admin/Inicio';
import { Inicio as InicioGestor } from '../Gestor/Inicio';
import { Redirect } from 'react-router-dom';

export interface IInicioRouter extends React.FC {}

const InicioRouter = (props: IInicioRouter) =>  {
    console.log(getSession().type)
    return (
        getSession().type === "admin" ? <Inicio/> : <Redirect to="manager/social-media"/>
    )
}

export default InicioRouter