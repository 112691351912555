import Request from "../util/Request"
import { AxiosResponse } from "axios"

type ResponseFazerLogin = {
    content: {
        token: string
        type: string
    },
    message: string
}

const FazerLogin = async (
    $type: 'admin' | 'manager',
    $form: {
        email: string,
        password: string
    },
    $success: ((response: ResponseFazerLogin) => void),
    $error: ((error: AxiosResponse) => void)
) => {
    await Request.post('/sign-' + $type, $form).then(response => {
        $success(response.data)
    }).catch($error)
}

export default FazerLogin;