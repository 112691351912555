import React, { useEffect, useState, useRef } from 'react'
import ConsultarSocialMedia from '../../../../../services/Manager/SocialMedia/ConsultarSocialMedia'
import Input from '../../../../components/Form/Input';
import Button from '../../../../components/Form/Button';

type formStatus = {
    id: string,
    name: string,
    email: string,
    password?: string
}
export interface ISocialMediaForm {
    typeRequest: 'create' | 'update',
    id: string,
    onUpdate: ((response: formStatus) => void),
    onCreate: ((response: formStatus) => void),
}

const SocialMediaForm: React.FunctionComponent<ISocialMediaForm> = (props) =>  {

    const defaultSocialMedia: formStatus = {
        id: '',
        name: '',
        email: '',
        password: ''
    }

    const [statusSelectForm, setstatusSelectForm] = useState<formStatus>(defaultSocialMedia)
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true

        if(props.id !== "0" && props.typeRequest === "update")
        {
            ConsultarSocialMedia(props.id, response => {
                if(isMounted.current)
                {
                    setstatusSelectForm(response.data.content)
                }
            }, error => {
                Error('Houve um erro ao buscar este status')
            })
        }else{
            setstatusSelectForm(defaultSocialMedia)
        }

        return () => {
            isMounted.current = false
        };
    }, [props.id, props.typeRequest])

    const onSubmitEvent = async (event: React.ChangeEvent<HTMLFormElement>) => {

        event.preventDefault()

        if(props.typeRequest === "update")
        {
            props.onUpdate(statusSelectForm)
        }

        if(props.typeRequest === "create")
        {
            props.onCreate(statusSelectForm)
        }
    }

    return (
        <form method="POST" onSubmit={onSubmitEvent}>
            <Input 
                configInput={{
                    className: "w-100",
                    value: statusSelectForm.name,
                    onChange: event => {
                        setstatusSelectForm(
                            {
                                ...statusSelectForm,
                                name: event.target.value
                            }
                        )
                    }
                }}
                label="Nome" />
            <Input 
                configInput={{
                    type: "email",
                    className: "w-100",
                    value: statusSelectForm.email,
                    onChange: event => {
                        setstatusSelectForm(
                            {
                                ...statusSelectForm,
                                email: event.target.value
                            }
                        )
                    }
                }}
                label="E-mail" />
            <Input 
                configInput={{
                    type: "password",
                    className: "w-100",
                    value: statusSelectForm.password,
                    onChange: event => {
                        setstatusSelectForm(
                            {
                                ...statusSelectForm,
                                password: event.target.value
                            }
                        )
                    }
                }}
                label="Senha" />
            <Button 
                buttonConfig={
                    {
                        className:"bg-primary clr-white"
                    }
                }
                >
                {
                    props.typeRequest === "create" ? 'Criar Social Media' : 'Atualizar Social Media'
                }
            </Button>
        </form>
    );
}

export default SocialMediaForm