import React, {useEffect, useState, useRef} from 'react';
import Sidebar from '../../../components/Sidebar';
import Table, { IColumnTable } from '../../../components/Table';
import { ClientConsult, IClientConsultResponse } from '../../../../services/Admin/Client';
import ConsultarGestors from '../../../../services/Admin/Manager/ConsultarGestores';
import { Link } from 'react-router-dom';

export interface IAdminInicioProps {}

const Inicio: React.FunctionComponent<IAdminInicioProps> = (props) =>  {

    const columnsClientes: IColumnTable = [
        {
            name: 'Cliente',
            campo: 'name',
            classNameItem: "bg-left-blue-6"
        }, 
        {
            name: 'Telefone',
            campo: 'contact_principal',
            classNameHeader: 'txt-align-center',
            classNameItem: 'justify-center ',
        },
        {
            name: 'WhatsApp',
            campo: 'whatapp_principal',
            classNameItem: 'justify-center',
            classNameHeader: 'txt-align-center',
        },
        {
            name: 'Valor mensal',
            campo: 'value_month',
            classNameHeader: 'txt-align-center',
            classNameItem: 'justify-center',
        }
    ]


    const columnsGestores: IColumnTable = [
        {
            name: 'Nome',
            campo: 'name',
            classNameItem: "bg-left-blue-6"
        }, 
        {
            name: 'E-mail',
            campo: 'email',
            classNameHeader: 'txt-align-center',
            classNameItem: 'justify-center ',
        }
    ]

    const isMounted = useRef(false)
    const [listClients, setListClient]  = useState<Array<IClientConsultResponse>>([])
    const [listGestores, setListGestores]  = useState<Array<any>>([])

    useEffect(() => {
        isMounted.current = true

        ClientConsult(response => {
            if(isMounted.current)
            {
                setListClient(response)
            }
        }, error => Error('Não foi possível recuperar a lista de clientes'))

        ConsultarGestors(response => {
            if(isMounted.current)
            {
                setListGestores(response.data.content)
            }
        }, error => Error('Não foi possível recuperar a lista de clientes'))

        return () => {
            isMounted.current = false;
        }
    }, [])


    return (
        <div>   
            <Sidebar />
            <div className="body__content">
                <div>
                    <h2 className="title__component">Gestores</h2>
                </div>
                <Table withoutSearch={true} withoutPagination={true} columns={columnsGestores} data={listGestores.slice(0,5)}/>
                <Link className="to-more" to="/admin/manager"><span><i className="fas fa-plus"></i> Ver mais </span></Link>
                <div className="">
                    <h2 className="title__component">Clientes</h2>
                </div>
                <Table withoutSearch={true} withoutPagination={true} columns={columnsClientes} data={listClients.slice(0,5)}/>
                <Link className="to-more" to="/admin/clientes"><span><i className="fas fa-plus"></i> Ver mais </span></Link>
            </div>
        </div>
    );
}

export default Inicio;