import { AxiosResponse } from 'axios';
import Request from '../../../util/Request';

const AtualizarComment = async (
    $form: {
        comment: string,
        id: string
    },
    $success: ((response: AxiosResponse) => void),
    $error: ((response: AxiosResponse) => void),
) => {
    await Request.put(`/manager/clients/comments/${$form.id}`, $form).then($success).catch($error)
}

export default AtualizarComment;