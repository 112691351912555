import Modal from "../Modal"

const Loading = ($text?: string) => {
    Modal.fire({
        title: 'Carregando..',
        text: $text,
        onBeforeOpen: () => {
            Modal.showLoading()
        }
    })
}


export default Loading