import React, { useEffect, useState, useRef } from 'react'
import Input from '../../../../../../components/Form/Input'
import Button from '../../../../../../components/Form/Button'
import { useParams } from 'react-router-dom'

type formStatus = {
    date_report: string,
    link: string,
    client_id: string
}

export interface IReportMonth {
    onCreate: ((response: formStatus) => void),
    clientId: string
}

const ReportMonth: React.FunctionComponent<IReportMonth> = (props) =>  {

    const defaultSocialMedia: formStatus = {
        date_report: '',
        link: '',
        client_id: props.clientId
    }

    const [statusSelectForm, setstatusSelectForm] = useState<formStatus>(defaultSocialMedia)
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true


        return () => {
            isMounted.current = false
        };
    }, [])

    const onSubmitEvent = async (event: React.ChangeEvent<HTMLFormElement>) => {

        event.preventDefault()

        props.onCreate(statusSelectForm)
    }

    return (
        <form method="POST" onSubmit={onSubmitEvent}>
            <Input 
                configInput={{
                    type: "date",
                    className: "w-100",
                    value: statusSelectForm.date_report,
                    onChange: event => {
                        setstatusSelectForm(
                            {
                                ...statusSelectForm,
                            date_report: event.target.value
                            }
                        )
                    }
                }}
                label="Data do relatório" />
            <Input 
                configInput={{
                    type: "text",
                    className: "w-100",
                    value: statusSelectForm.link,
                    onChange: event => {
                        setstatusSelectForm(
                            {
                                ...statusSelectForm,
                                link: event.target.value
                            }
                        )
                    }
                }}
                label="Link relatório" />
            <Button 
                buttonConfig={
                    {
                        className:"bg-primary clr-white"
                    }
                }
                >
                    Criar relatório
            </Button>
        </form>
    );
}

export default ReportMonth