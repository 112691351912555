import Cookies from 'universal-cookie'

const cookies = new Cookies()

const cookieGet = ($param: string) => 
{
    return cookies.get($param)
}

const cookieRemove = ($param: string) => 
{
    cookies.remove($param)
}

const cookieSet = ($param: string, $value: string) => 
{
    cookies.set($param, $value)
}

const setToken = ($value: string) => 
{
    cookies.set('X-Request-Token', $value)
}

const removeToken = () => 
{
    cookies.remove('X-Request-Token')
}

const getToken = () => 
{
    return cookies.get('X-Request-Token')
}

const setType = ($value: string) => 
{
    cookies.set('X-Request-Type', $value)
}

const removeType = () => 
{
    cookies.remove('X-Request-Type')
}

const getType = () => 
{
    return cookies.get('X-Request-Type')
}

export {
    cookieSet,
    cookieRemove,
    cookieGet,
    removeToken,
    getToken,
    setToken,
    removeType,
    getType,
    setType
}