import React, { useEffect, useState, useRef } from 'react'
import Input from '../../../../components/Form/Input'
import Button from '../../../../components/Form/Button'
import SelectBox from '../../../../components/Form/SelectBox'
import Success from '../../../../../util/Modals/Success'
import { IListStatusItem } from '../../../../../services/Admin/Status/ListStatus'
import StatusById from '../../../../../services/Admin/Status/StatusById'

type formStatus = {
    label: string,
    color: string
    id?: string
}
export interface IStatusForm {
    typeRequest: 'create' | 'update',
    id: string,
    onUpdate: ((response: formStatus) => void),
    onCreate: ((response: formStatus) => void),
    status: 'content' | 'finance' | 'report-simple' | 'calendar'
}

const StatusForm: React.FunctionComponent<IStatusForm> = (props) =>  {

    const defaultStatus = {
        id: '',
        label: '',
        color: ''
    };

    const [statusSelectForm, setstatusSelectForm] = useState<IListStatusItem>(defaultStatus)
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true

        if(props.id !== "0" && props.typeRequest === "update")
        {
            StatusById(props.id, props.status, response => {
                if(isMounted.current)
                {
                    setstatusSelectForm(response)
                }
            }, error => {
                Error('Houve um erro ao buscar este status')
            })
        }else{
            setstatusSelectForm(defaultStatus)
        }

        return () => {
            isMounted.current = false
        };
    }, [props.id, props.typeRequest])

    const onSubmitEvent = async (event: React.ChangeEvent<HTMLFormElement>) => {

        event.preventDefault()

        if(props.typeRequest === "update")
        {
            props.onUpdate(statusSelectForm)
        }

        if(props.typeRequest === "create")
        {
            props.onCreate(statusSelectForm)
        }
    }

    return (
        <form method="POST" onSubmit={onSubmitEvent}>
            <Input 
                configInput={{
                    className: "w-100",
                    value: statusSelectForm.label,
                    onChange: event => {
                        setstatusSelectForm(
                            {
                                ...statusSelectForm,
                                label: event.target.value
                            }
                        )
                    }
                }}
                label="Nome do status" />
            <Input 
                configInput={{
                    className: "w-100",
                    value: statusSelectForm.color,
                    style: {
                        borderBottom: `2px solid ${statusSelectForm.color}`
                    },
                    onChange: event => {
                        setstatusSelectForm(
                            {
                                ...statusSelectForm,
                                color: event.target.value
                            }
                        )
                    }
                }}
                label="Cor (use este formato: #FFFFFF)" />
            <Button 
                buttonConfig={
                    {
                        className:"bg-primary clr-white"
                    }
                }
                >
                {
                    props.typeRequest === "create" ? 'Criar status' : 'Atualizar status'
                }
            </Button>
        </form>
    );
}

export default StatusForm