import React, { useState, useRef, useEffect } from 'react'
import SidebarManager from '../../../components/SidebarManager';
import Table, { IColumnTable } from '../../../components/Table';
import ConsultarRelatorioRapido from '../../../../services/Manager/ConsultarRelatorioRapido';
import { useHistory } from 'react-router-dom';

export interface IRelatorioRapido {}

const RelatorioRapido: React.FunctionComponent<IRelatorioRapido> = (props) =>  {
    const [listClients, setListClient]  = useState<Array<any>>([])
    const isMounted = useRef(false)

    useEffect(() => {
        isMounted.current = true

        ConsultarRelatorioRapido(response => {
            if(isMounted.current)
            {
                console.log(response.data.content)
                setListClient(response.data.content)
            }
        }, error => Error('Não foi possível recuperar a lista de clientes e social medias.'))
        
        return () => {
            isMounted.current = false;
        }
    }, [])

    const router = useHistory()

    const columnsTable: IColumnTable = [
        {
            name: 'Cliente',
            campo: 'client_name',
            componentDefault: (row: any) => {
                return (
                    <div className="bg-left-blue-4">
                        <span title="Clique para abrir os conteúdos cliente com seu Social media." className="link-text-span" onClick={() => {
                            router.push(`/manager/social-media/${row.user_id}/clients/${row.client_id}`)
                        }}>
                            {row.client_name} <i className="fas fa-link"></i>
                        </span>
                    </div>
                )
            }
        }, 
        {
            name: 'Social Media',
            campo: 'user_name',
            classNameHeader: 'txt-align-center',
            componentDefault: (row: any) => {
                return (
                    <div className="justify-center">
                        <span title="Clique para abrir os clientes deste Social Media" className="link-text-span" onClick={() => {
                            router.push(`/manager/social-media/${row.user_id}/clients`)
                        }}>
                            {row.user_name} <i className="fas fa-link"></i>
                        </span>
                    </div>
                )
            }
        },
    ]

    return (
        <div>
            <SidebarManager/>
            <div className="body__content">
                <h1 className="title__component">Relatório de clientes</h1>
                <Table columns={columnsTable} data={listClients} />
            </div>
        </div>
    );
}

export default RelatorioRapido