import { AxiosResponse } from 'axios'
import Request from '../../util/Request'

const UpdateReportMonth = async (
    id: string,
    data: {
        link?: string,
        date_report?: string,
        id?: string
    },
    success: ((response: AxiosResponse) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.put(`/manager/clients/report-month/${id}`, data).then(success).catch(error)


export default UpdateReportMonth