import { AxiosResponse } from 'axios';
import Request from '../util/Request';

export interface IMeuCliente {
    id: string,
    name: string,
    contact_principal: string,
    email_principal: string,
    whatapp_principal: string,
    city: string,
    website: string,
    briefing_client: string,
    actions_contract: string,
    value_month: string,
    publish_month: string,
    drive_href: string,
    layout_default: string,
    about_plain: string,
    user_onboarding: string,
    about_client: string,
}

const ConsultClient = async (
    $id: string,
    $type: 'manager' | 'admin',
    $successLogin: ((data: IMeuCliente) => void),
    $errorLogin: ((error: AxiosResponse) => void)
) => {
    await Request.get(`/${$type}-client-informations/` + $id).then(
        response => {
            $successLogin(response.data.content)
        },
        $errorLogin
    )
}

export default ConsultClient