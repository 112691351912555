import React, {useState} from "react";
import Pagination from 'react-js-pagination'

// Column
export interface IColumnTableItem {
    name: string,
    icon?: string,
    iconPosition?: 'left' | 'right'
    color?: string,
    background?: string,
    campo: string,
    classNameHeader?: string,
    classNameItem?: string,
    componentDefault?: (row: any) => JSX.Element,
    onClickItem?: (() => void),
    isCount?: boolean
}

export interface IColumnTable extends Array<IColumnTableItem> {}

// Tabela 
export interface ITableProps {
    columns: IColumnTable,
    data: Array<any>,
    classNameLineItens?: string,
    classNameLineHeader?: string,
    withoutSearch?: boolean,
    withoutPagination?: boolean,
    withoutFooter?: boolean
}

const Table: React.FunctionComponent<ITableProps> = (props) =>  {
    
    // Informações para iniciar a tabela
    const { columns, data } = props;
    // Filtro de texto
    const [filterText, setfilterText] = useState('')
    // Pagina selecionada
    const [activePage, setActivePage] = useState(1)
    // Maximo de paginas
    const [pageSizes] = useState(10)

    // Filtro
    const filteredItems = data.filter(item => {
        let status = false;

        for(const [key] of Object.entries(item)) 
        {
            if(item[`${key}`])
            {
                if(item[`${key}`].toLowerCase().includes(filterText.toLowerCase()))
                {
                    status = true
                    break
                }
            }else{
                continue;
            }
        }

        return status
    });

    // Paginado
    const paginate = filteredItems.slice((activePage - 1) * pageSizes, activePage * pageSizes);


    // Alterador de paginas
    const handlePageChange = (pageNumber: any) => 
    {
        setActivePage(pageNumber)
    }

    return (
        <>
            {
                props.withoutSearch ? null : (
                    <div className="table__search">
                        <span>Busque o que deseja </span><input type="search" placeholder="Digite aqui.." onChange={event => setfilterText(event.target.value)}/>
                    </div>
                )
            }
            <div className="table__app">
                <div className={props.classNameLineHeader ?? 'table__app__header'}>
                    {
                        columns.map((item, index )=> {
                            return (
                                <div className={item.classNameHeader} key={`${index}`}>{item.name}</div>
                            )
                        })
                    }
                </div>
                {
                    // Faço um loop da lista de dados.
                    paginate.map((item_data, index) => {
                        return (
                            <div key={`${index}`} className={props.classNameLineItens ?? 'table__content__item'}>
                                {
                                    // Crio a tabela conforme as colunas setas.
                                    columns.map((item: any, index ) => {
                                        // Se o campo bate
                                        if(item_data[item.campo])
                                        {
                                            // Se o campo exister um componente alterado
                                            if(item.componentDefault)
                                            {
                                                return item.componentDefault(item_data)
                                            }else{
                                                // Se o campo exister um componente normal
                                                return (
                                                    <div key={`${index}`} className={item.classNameItem}>{item_data[item.campo]}</div>
                                                )
                                            }
                                        }else{
                                            // Se o campo for options
                                            if(item.campo === "options")
                                            {
                                                // Se o campo existir um componente alterado
                                                return item.componentDefault(item_data)
                                            }else{
                                                // Se o campo existir um componente normal
                                                return (
                                                    <div key={`${index}`}  className={item.classNameItem}>---</div>
                                                )
                                            }
                                        }
                                    })
                                }
                            </div>
                        )
                    })
                }
                {
                    props.withoutFooter ? null : (
                        <div className={props.classNameLineHeader ?? 'table__app__footer'}>
                            {
                                columns.map((item: IColumnTableItem, index )=> {
                                    // Se o componente for contavel
                                    if(item.isCount)
                                    {
                                        let total_value = 0;

                                        // Soma todos os items
                                        data.map(items => {
                                            total_value += parseFloat(items[item.campo])

                                            return items
                                        })

                                        // Imprime
                                        return (
                                            <div className="table__content__item__focus __row  __value justify-center">
                                                <p className="value">{total_value}</p>
                                                <p className="text">Total</p>
                                            </div>
                                        )
                                    }else{
                                        return (
                                            <div></div>
                                        )
                                    }
                                })
                            }
                        </div>
                    )
                }
            </div>
            {
                props.withoutPagination ? null : (
                    // Paginação
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={pageSizes}
                        totalItemsCount={filteredItems.length}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />
                )
            }
        </>
    );
}

export default Table;