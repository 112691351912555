import React, { useEffect, useState, useRef } from 'react'
import Input from '../../../../../../components/Form/Input'
import Button from '../../../../../../components/Form/Button'
import { useParams } from 'react-router-dom'
import ConsultarNPSSocialMedia from '../../../../../../../services/Manager/SocialMedia/NPS/ConsultarNPSSocialMedia'

type formStatus = {
    stars: string,
    data: string,
    feedback: string
}

export interface IContentForm {
    onUpdate: ((response: formStatus) => void),
}

const NPSForm: React.FunctionComponent<IContentForm> = (props) =>  {

    const { id, client_id } = useParams()

    const defaultSocialMedia: formStatus = {
        stars: '',
        data: '',
        feedback: ''
    }

    const [statusSelectForm, setstatusSelectForm] = useState<formStatus>(defaultSocialMedia)
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true

        ConsultarNPSSocialMedia(id, client_id, response => {
            if(isMounted.current)
            {
                setstatusSelectForm(
                    {
                        data: response.data.content.data,
                        stars: response.data.content.stars,
                        feedback: response.data.content.feedback,
                    }
                )
            }
        }, error => {
            Error('Houve um erro ao buscar este status')
        })

        return () => {
            isMounted.current = false
        };
    }, [id, client_id])

    const onSubmitEvent = async (event: React.ChangeEvent<HTMLFormElement>) => {

        event.preventDefault()

        props.onUpdate(statusSelectForm)
    }

    return (
        <form method="POST" onSubmit={onSubmitEvent}>
            <Input 
                configInput={{
                    type: "number",
                    className: "w-100",
                    value: statusSelectForm.stars,
                    onChange: event => {
                        setstatusSelectForm(
                            {
                                ...statusSelectForm,
                                stars: event.target.value
                            }
                        )
                    }
                }}
                label="Pontuação" />
            <Input 
                configInput={{
                    type: "text",
                    className: "w-100",
                    value: statusSelectForm.feedback,
                    onChange: event => {
                        setstatusSelectForm(
                            {
                                ...statusSelectForm,
                                feedback: event.target.value
                            }
                        )
                    }
                }}
                label="Feedback" />
            <Input 
                configInput={{
                    type: "date",
                    className: "w-100",
                    value: statusSelectForm.data,
                    onChange: event => {
                        setstatusSelectForm(
                            {
                                ...statusSelectForm,
                                data: event.target.value
                            }
                        )
                    }
                }}
                label="Data" />
            <Button 
                buttonConfig={
                    {
                        className:"bg-primary clr-white"
                    }
                }
                >Atualizar NPS
            </Button>
        </form>
    );
}

export default NPSForm