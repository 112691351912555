import axios from 'axios'
import Cookies from 'universal-cookie'
import Loading from '../Modals/Loading';
import Modal from '../Modals/Modal';

const Request = axios.create({
    baseURL: 'https://www.server-app.3mind.com.br'
    // baseURL: 'http://server-3mind'
})

const cookies = new Cookies()

Request.interceptors.request.use((config) => {

    config.headers['X-Request-Token'] = cookies.get('X-Request-Token')

    Loading('Buscando informações..')

    return config
});


Request.interceptors.response.use(response => {
    Modal.close()

    return response;
}, error => {
    Modal.close()

    return Promise.reject(error.response)
});

export default Request;