import React, { useEffect, useState, useRef } from 'react'
import Input from '../../../../../../components/Form/Input'
import Button from '../../../../../../components/Form/Button'
import Select from 'react-select'
import ListContentStatus from '../../../../../../../services/Manager/ListContentStatus'
import { useParams } from 'react-router-dom'
import ConsultarContent from '../../../../../../../services/Manager/SocialMedia/Content/ConsultarContent'

type formStatus = {
    id: string,
    client_id: string,
    user_id: string,
    status: string,
    period_start: string,
    period_end: string,
    publishs: string,
    content_href: string,
    obs: string,
}
export interface IContentForm {
    typeRequest: 'create' | 'update',
    id: string,
    onUpdate: ((response: formStatus) => void),
    onCreate: ((response: formStatus) => void),
}

const ContentForm: React.FunctionComponent<IContentForm> = (props) =>  {

    const { id, client_id } = useParams<any>()

    const defaultSocialMedia: formStatus = {
        id: '',
        client_id: client_id,
        user_id: id,
        status: '',
        period_start: '',
        period_end: '',
        publishs: '',
        content_href: '',
        obs: '',
    }

    const [statusSelectForm, setstatusSelectForm] = useState<formStatus>(defaultSocialMedia)
    const [contentStatus, setContentStatus] = useState<any>({})
    const [status, setStatus] = useState<any>({})
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true

        ListContentStatus(response => {
            if(isMounted.current)
            {
                setContentStatus(response.data.content)
            }
        }, error => {
            Error('Houve um erro ao buscar este status')
        })

        if(props.id !== "0" && props.typeRequest === "update")
        {
            ConsultarContent(props.id, response => {
                if(isMounted.current)
                {
                    setstatusSelectForm(response.data.content)
                }
            }, error => {
                Error('Houve um erro ao buscar este status')
            })
        }else{
            setstatusSelectForm(defaultSocialMedia)
        }

        return () => {
            isMounted.current = false
        };
    }, [props.id, props.typeRequest])

    const onSubmitEvent = async (event: React.ChangeEvent<HTMLFormElement>) => {

        event.preventDefault()

        if(props.typeRequest === "update")
        {
            props.onUpdate(statusSelectForm)
        }

        if(props.typeRequest === "create")
        {
            props.onCreate(statusSelectForm)
        }
    }

    return (
        <form method="POST" onSubmit={onSubmitEvent}>
            <div className="input-box">
                <label>Escolha um status</label>
                <Select 
                    value={status} 
                    getOptionLabel={e => e.label} 
                    getOptionValue={e => e.id} 
                    options={contentStatus} 
                    onChange={item => {
                        setstatusSelectForm(
                            {
                                ...statusSelectForm,
                                status: item.id
                            }
                        )
                        setStatus(item)
                    }
                    } 
                    />
            </div>
            <Input 
                configInput={{
                    type: "date",
                    className: "w-100",
                    value: statusSelectForm.period_start,
                    onChange: event => {
                        setstatusSelectForm(
                            {
                                ...statusSelectForm,
                                period_start: event.target.value
                            }
                        )
                    }
                }}
                label="Período inicial" />
            <Input 
                configInput={{
                    type: "date",
                    className: "w-100",
                    value: statusSelectForm.period_end,
                    onChange: event => {
                        setstatusSelectForm(
                            {
                                ...statusSelectForm,
                                period_end: event.target.value
                            }
                        )
                    }
                }}
                label="Período final" />
            <Input 
                configInput={{
                    type: "number",
                    className: "w-100",
                    value: statusSelectForm.publishs,
                    onChange: event => {
                        setstatusSelectForm(
                            {
                                ...statusSelectForm,
                                publishs: event.target.value
                            }
                        )
                    }
                }}
                label="Numero de publicações" />
            <Input 
                configInput={{
                    className: "w-100",
                    value: statusSelectForm.content_href,
                    onChange: event => {
                        setstatusSelectForm(
                            {
                                ...statusSelectForm,
                                content_href: event.target.value
                            }
                        )
                    }
                }}
                label="Digite o link" />
            <Input 
                configInput={{
                    className: "w-100",
                    value: statusSelectForm.obs,
                    onChange: event => {
                        setstatusSelectForm(
                            {
                                ...statusSelectForm,
                                obs: event.target.value
                            }
                        )
                    }
                }}
                label="Observação" />
            <Button 
                buttonConfig={
                    {
                        className:"bg-primary clr-white"
                    }
                }
                >
                {
                    props.typeRequest === "create" ? 'Criar conteúdo' : 'Atualizar conteúdo'
                }
            </Button>
        </form>
    );
}

export default ContentForm