import { AxiosResponse } from 'axios'
import Request from '../../../util/Request'
import { IListStatusItem } from './ListStatus'

const CriarStatus = async (
    $form: {
        label: string,
        color: string
    },
    $status: "calendar" | "content" | "finance" | "report-simple",
    success: ((response: IListStatusItem) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.post('admin/status/'+ $status, $form).then(response => {
    success(response.data.content)
}).catch(error)

export default CriarStatus