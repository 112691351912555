import React, { useEffect, useState, useRef } from 'react'
import Input from '../../../../components/Form/Input'
import Button from '../../../../components/Form/Button'
import Success from '../../../../../util/Modals/Success'
import ConsultarGestor from '../../../../../services/Admin/Manager/ConsultarGestor'

type formStatus = {
    name: string,
    password: string
    email: string
    id?: string
}
export interface IStatusForm {
    typeRequest: 'create' | 'update',
    id: string,
    onUpdate: ((response: formStatus) => void),
    onCreate: ((response: formStatus) => void),
}

const ManagerForm: React.FunctionComponent<IStatusForm> = (props) =>  {

    const defaultStatus: formStatus = {
        id: '',
        password: '',
        email: '',
        name: ''
    };

    const [statusSelectForm, setstatusSelectForm] = useState<formStatus>(defaultStatus)
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true

        if(props.id !== "0" && props.typeRequest === "update")
        {
            ConsultarGestor(props.id, response => {
                if(isMounted.current)
                {
                    setstatusSelectForm(response.data.content)
                }
            }, error => {
                Error('Houve um erro ao buscar este status')
            })
        }else{
            setstatusSelectForm(defaultStatus)
        }

        return () => {
            isMounted.current = false
        };
    }, [props.id, props.typeRequest])

    const onSubmitEvent = async (event: React.ChangeEvent<HTMLFormElement>) => {

        event.preventDefault()

        if(props.typeRequest === "update")
        {
            props.onUpdate(statusSelectForm)
        }

        if(props.typeRequest === "create")
        {
            props.onCreate(statusSelectForm)
        }
    }

    return (
        <form method="POST" onSubmit={onSubmitEvent}>
            <Input 
                configInput={{
                    className: "w-100",
                    value: statusSelectForm.name,
                    onChange: event => {
                        setstatusSelectForm(
                            {
                                ...statusSelectForm,
                                name: event.target.value
                            }
                        )
                    }
                }}
                label="Nome" />
            <Input 
                configInput={{
                    className: "w-100",
                    value: statusSelectForm.email,
                    onChange: event => {
                        setstatusSelectForm(
                            {
                                ...statusSelectForm,
                                email: event.target.value
                            }
                        )
                    }
                }}
                label="E-mail" />
            {
                props.typeRequest === "create" ?
                (
                    <Input 
                        configInput={{
                            className: "w-100",
                            type: "password",
                            value: statusSelectForm.password,
                            onChange: event => {
                                setstatusSelectForm(
                                    {
                                        ...statusSelectForm,
                                        password: event.target.value
                                    }
                                )
                            }
                        }}
                        label="Senha" />
                ) : null
            }
            <Button 
                buttonConfig={
                    {
                        className:"bg-primary clr-white"
                    }
                }
                >
                {
                    props.typeRequest === "create" ? 'Criar gestor' : 'Atualizar gestor'
                }
            </Button>
        </form>
    );
}

export default ManagerForm