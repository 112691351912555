import React from "react"
import {
  Route,
  RouteProps,
  Redirect,
} from "react-router-dom"

import { cookieGet } from "../../util/Cookies"


interface IRouteProtect extends RouteProps {
    type?: 'admin' | 'manager'
}

const RouteProtect = (props: IRouteProtect) => 
{
    if(cookieGet('X-Request-Token') && cookieGet('X-Request-Type'))
    {
        if(props.type && cookieGet('X-Request-Type') === props.type)
        {
            return <Route {...props} /> 
        }else{
            return <Route {...props} />
        }
    }else{
        return <Redirect to="/entrar"/> 
    }
}

export default RouteProtect;