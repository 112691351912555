import React from 'react'
import Table, { IColumnTable } from '../../../../components/Table'
import { IListStatusItem } from '../../../../../services/Admin/Status/ListStatus'

export interface ITableDefault {
    data?: any,
    onClickEdit: ((data: IListStatusItem) => void),
    onClickDelete: ((data: IListStatusItem) => void)
}

const TableDefault: React.FunctionComponent<ITableDefault> = (props) =>  {
    const columnsTable: IColumnTable = [
        {
            name: 'Nome',
            campo: 'label',
            classNameItem: "bg-left-blue-6"
        }, 
        {
            name: 'Color',
            campo: 'color',
            classNameHeader: 'txt-align-center',
            classNameItem: 'justify-center ',
            componentDefault: (row: IListStatusItem) => {
                return (
                    <div className="justify-center" style={{backgroundColor: row.color}}>
                        <span className="clr-white">{row.color}</span>
                    </div>
                )
            }
        },
        {
            name: 'Opções',
            campo: 'options',
            classNameHeader: 'txt-align-center',
            componentDefault: (row: IListStatusItem) => {
                return (
                    <div className="justify-center">
                        <span className="link-table-action bg-primary" onClick={() => props.onClickEdit(row)}>
                            <i className="fas fa-edit"></i>
                            <p>Editar</p>
                        </span>
                        <span className="link-table-action bg-danger" onClick={() => props.onClickDelete(row)}>
                            <i className="fas fa-trash-alt"></i>
                            <p>Apagar</p>
                        </span>
                    </div>
                )
            }
        },
    ]

    return (
        <div>
            <Table data={props.data} columns={columnsTable}/>
        </div>
    );
}

export default TableDefault