import React, { useEffect, useState, useRef } from 'react'
import Button from '../../../../../components/Form/Button';
import Select from 'react-select';
import ListClients from '../../../../../../services/Manager/ListClients';
import ListMyClientStatus from '../../../../../../services/Manager/ListMyClientStatus';
import AtualizarStatus from '../../../../../../services/Manager/SocialMedia/Clients/AtualizarStatus';
import Success from '../../../../../../util/Modals/Success';
import CadastrarCliente from '../../../../../../services/Manager/SocialMedia/Clients/CadastrarCliente';
import Error from '../../../../../../util/Modals/Error';

export interface ISocialMediaForm {
    typeRequest: 'create' | 'update',
    id: string,
    onChange: (() => void),
    socialMedia: string
}

const ClientsForm: React.FunctionComponent<ISocialMediaForm> = (props) =>  {

    const isMounted = useRef(false);

    const [listClients, setlistClients] = useState([])
    const [listStatus, setlistStatus] = useState([])

    useEffect(() => {
        isMounted.current = true

        if(props.id !== "0" && props.typeRequest === "update")
        {
            ListMyClientStatus(response => {
                if(isMounted.current)
                {
                    setlistStatus(response.data.content)
                }
            }, error => {
                Error('Houve um erro ao buscar este status')
            })
        }else{
            ListClients(success => {
                setlistClients(success.data.content)
            }, error => {
                Error('Houve um erro ao buscar os clientes')
            })
        }

        return () => {
            isMounted.current = false
        };
    }, [props.id, props.typeRequest])

    const onSubmitEvent = async (event: React.ChangeEvent<HTMLFormElement>) => {

        event.preventDefault()

        if(props.typeRequest === "update")
        {
            AtualizarStatus(props.id, {
                status: status
            }, success => {
                Success('Atualizado com sucesso!', () => {
                    props.onChange()
                })
            }, error => {
                Error('Não foi possível atualizar esse status')
            })
        }

        if(props.typeRequest === "create")
        {
            CadastrarCliente({
                client_id: client.id,
                user_id: props.socialMedia
            }, success => {
                Success('Atualizado com sucesso!', () => {
                    props.onChange()
                })
            }, error => {
                Error(error.data.message ?? 'Não foi possível cadastrar esse cliente')
            })
        }
    }

    const [client, setClient] = useState<any>({})
    const [status, setStatus] = useState<any>({})

    if(props.typeRequest === "create")
    {
        return (
            <form method="POST" onSubmit={onSubmitEvent}>
                <div className="input-box">
                    <label>Escolha um cliente</label>
                    <Select 
                        value={client} 
                        getOptionLabel={e => e.name} 
                        getOptionValue={e => e.id} 
                        options={listClients} 
                        onChange={setClient} 
                        />
                </div>
                <Button 
                    buttonConfig={
                        {
                            className:"bg-primary clr-white"
                        }
                    }
                    >Associar cliente</Button>
            </form>
        )
    } else
    {
        return (
            <form method="POST" onSubmit={onSubmitEvent}>
                <div className="input-box">
                    <label>Novo status</label>
                    <Select 
                        value={status} 
                        getOptionLabel={e => e.label} 
                        getOptionValue={e => e.id} 
                        options={listStatus} 
                        onChange={setStatus} 
                    />
                </div>
                <Button 
                    buttonConfig={
                        {
                            className:"bg-primary clr-white"
                        }
                }>Atualizar status</Button>
            </form>
        )
    }
}

export default ClientsForm