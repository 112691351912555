import { AxiosResponse } from 'axios'
import Request from '../../../util/Request'

const CriarSocialMedia = async (
    data: any,
    success: ((response: AxiosResponse) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.post('/manager/social-media', data).then(success).catch(error)


export default CriarSocialMedia