import React, { useEffect, useState, useRef } from 'react'
import Input from '../../../../components/Form/Input'
import Button from '../../../../components/Form/Button'
import ConsultById from '../../../../../services/Admin/Client/ConsultById'
import { IClientConsultResponse } from '../../../../../services/Admin/Client'
import ConsultMidia from '../../../../../services/Admin/Client/ConsultMidia'
import ConsultMidiaList, { IMidiaItem } from '../../../../../services/ConsultMidiaList'
import SelectBox from '../../../../components/Form/SelectBox'
import UpdateMidias from '../../../../../services/Admin/Client/UpdateMidias'
import AtualizarById from '../../../../../services/Admin/Client/AtualizarById'
import Success from '../../../../../util/Modals/Success'
import { useHistory } from 'react-router-dom'
import CriarCliente from '../../../../../services/Admin/Client/CriarCliente'
import CriarClienteMidias from '../../../../../services/Admin/Client/CriarClienteMidias'

export interface IClientForm {
    typeRequest: 'create' | 'update',
    id: string,
    onUpdate: ((response: any) => void),
    onCreate: ((response: any) => void)
}

const ClientForm: React.FunctionComponent<IClientForm> = (props) =>  {

    const defaultClient = {
        id: '',
        name: '',
        contact_principal: '',
        email_principal: '',
        whatapp_principal: '',
        city: '',
        website: '',
        briefing_client: '',
        actions_contract: '',
        value_month: '',
        publish_month: '',
        drive_href: '',
        layout_default: '',
        about_plain: '',
        user_onboarding: '',
        about_client: ''
    };

    const [clientSelectedForm, setClientSelectedForm] = useState<IClientConsultResponse>(defaultClient)

    const [clientSelectedMidia, setClientSelectedMidia] = useState<Array<IMidiaItem>>([])
    const [clientMidiaList, setClientMidiaList] = useState<Array<IMidiaItem>>([])

    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true

        if(props.id !== "0" && props.typeRequest === "update")
        {
            ConsultMidiaList(response => {
                if(isMounted.current)
                {
                    setClientMidiaList(response)

                    if(props.typeRequest === "update")
                    {
                        ConsultMidia(props.id, response => {
                            if(isMounted.current)
                            {
                                setClientSelectedMidia(response)
                                console.log(response)
                            }
                        }, error => {
                            Error('Não foi possível carregar este usuário')
                        })
                    }
                }
            }, error => {
                Error('Não foi possível carregar este usuário')
            });

            ConsultById(props.id, response => {
                if(isMounted.current)
                {
                    setClientSelectedForm(response)
                }
            }, error => {
                Error('Não foi possível carregar este usuário')
            })
        }else{
            setClientSelectedForm(defaultClient)
            setClientSelectedMidia([])
            ConsultMidiaList(response => {
                if(isMounted.current)
                {
                    setClientMidiaList(response)
                }
            }, error => {
                Error('Não foi possível carregar este usuário')
            });
        }

        return () => {
            isMounted.current = false
        };
    }, [props.id, props.typeRequest])

    const onSubmitEvent = async (event: React.ChangeEvent<HTMLFormElement>) => {

        event.preventDefault()

        if(props.typeRequest === "update")
        {
            await UpdateMidias(props.id, clientSelectedMidia, async (response_midia) => {
                await AtualizarById(props.id, clientSelectedForm, response => {
                    Success(response.data.message, () => {
                        props.onUpdate(response.data.content)
                    })
                }, error => {
                    Error('Não foi possível atualizar esses clientes')
                })
            }, error => {
                Error('Não foi possível atualizar as mídias')
            })
        }

        if(props.typeRequest === "create")
        {
            await CriarCliente(clientSelectedForm, async (response_criar) => {
                await CriarClienteMidias(response_criar.data.content.id, clientSelectedMidia, response => {
                    Success('Criado com sucesso', () => {
                        props.onCreate(response_criar.data.content)
                    })
                }, error => {
                    Error('Cliente criado entre, mas houve um erro ao criar as midias. Atualize mais tarde!')
                })
            }, error => {
                Error('Não foi possível criar esse cliente')
            })
        }
    }

    return (
        <form method="POST" onSubmit={onSubmitEvent}>
            <Input 
                configInput={{
                    className: "w-100",
                    value: clientSelectedForm.name,
                    onChange: event => {
                        setClientSelectedForm(
                            {
                                ...clientSelectedForm,
                                name: event.target.value
                            }
                        )
                    }
                }}
                label="Nome do escritório (fantasia)" />
            <Input 
                configInput={{
                    className: "w-100",
                    value: clientSelectedForm.contact_principal,
                    onChange: event => {
                        setClientSelectedForm(
                            {
                                ...clientSelectedForm,
                                contact_principal: event.target.value
                            }
                        )
                    }
                }}
                label="Contato principal" />
            <Input 
                configInput={{
                    className: "w-100",
                    value: clientSelectedForm.email_principal,
                    onChange: event => {
                        setClientSelectedForm(
                            {
                                ...clientSelectedForm,
                                email_principal: event.target.value
                            }
                        )
                    }
                }}
                label="E-mail do contato principal" />
            <Input 
                configInput={{
                    className: "w-100",
                    value: clientSelectedForm.whatapp_principal,
                    onChange: event => {
                        setClientSelectedForm(
                            {
                                ...clientSelectedForm,
                                whatapp_principal: event.target.value
                            }
                        )
                    }
                }}
                label="Whatsapp do contato principal" />
            <Input 
                configInput={{
                    className: "w-100",
                    value: clientSelectedForm.city,
                    onChange: event => {
                        setClientSelectedForm(
                            {
                                ...clientSelectedForm,
                                city: event.target.value
                            }
                        )
                    }
                }}
                label="Cidade (sede do cliente)" />
            <SelectBox 
                label="Midias"
                options={clientMidiaList} 
                onChange={(items: any) => {
                    setClientSelectedMidia(items)
                }}
                value={clientSelectedMidia}
                getOptionValue={(x: any) => x.id}
                getOptionLabel={(x: any) => x.label}

            />
            <Input 
                configInput={{
                    className: "w-100",
                    value: clientSelectedForm.website,
                    onChange: event => {
                        setClientSelectedForm(
                            {
                                ...clientSelectedForm,
                                website: event.target.value
                            }
                        )
                    }
                }}
                label="Site do cliente" />
            <Input 
                configInput={{
                    className: "w-100",
                    value: clientSelectedForm.briefing_client,
                    onChange: event => {
                        setClientSelectedForm(
                            {
                                ...clientSelectedForm,
                                briefing_client: event.target.value
                            }
                        )
                    }
                }}
                label="Briefing do cliente" />
            <Input 
                configInput={{
                    className: "w-100",
                    value: clientSelectedForm.actions_contract,
                    onChange: event => {
                        setClientSelectedForm(
                            {
                                ...clientSelectedForm,
                                actions_contract: event.target.value
                            }
                        )
                    }
                }}
                label="Ações contratadas (mídias)" />
            <Input 
                configInput={{
                    className: "w-100",
                    value: clientSelectedForm.value_month,
                    onChange: event => {
                        setClientSelectedForm(
                            {
                                ...clientSelectedForm,
                                value_month: event.target.value
                            }
                        )
                    }
                }}
                label="Valor do plano mensal" />
            <Input 
                configInput={{
                    className: "w-100",
                    value: clientSelectedForm.publish_month,
                    onChange: event => {
                        setClientSelectedForm(
                            {
                                ...clientSelectedForm,
                                publish_month: event.target.value
                            }
                        )
                    }
                }}
                label="Publicações mensais" />
            <Input 
                configInput={{
                    className: "w-100",
                    value: clientSelectedForm.drive_href,
                    onChange: event => {
                        setClientSelectedForm(
                            {
                                ...clientSelectedForm,
                                drive_href: event.target.value
                            }
                        )
                    }
                }}
                label="Drive do cliente" />
            <Input 
                configInput={{
                    className: "w-100",
                    value: clientSelectedForm.layout_default,
                    onChange: event => {
                        setClientSelectedForm(
                            {
                                ...clientSelectedForm,
                                layout_default: event.target.value
                            }
                        )
                    }
                }}
                label="Padrão de layout" />
            <Input 
                configInput={{
                    className: "w-100",
                    value: clientSelectedForm.about_plain,
                    onChange: event => {
                        setClientSelectedForm(
                            {
                                ...clientSelectedForm,
                                about_plain: event.target.value
                            }
                        )
                    }
                }}
                label="Sobre o plano de ação" />
            <Input 
                configInput={{
                    className: "w-100",
                    value: clientSelectedForm.user_onboarding,
                    onChange: event => {
                        setClientSelectedForm(
                            {
                                ...clientSelectedForm,
                                user_onboarding: event.target.value
                            }
                        )
                    }
                }}
                label="Responsável pelo Onboarding" />
            <Input 
                configInput={{
                    className: "w-100",
                    value: clientSelectedForm.about_client,
                    onChange: event => {
                        setClientSelectedForm(
                            {
                                ...clientSelectedForm,
                                about_client: event.target.value
                            }
                        )
                    }
                }}
                label="Observações sobre o cliente" />
            <Button 
                buttonConfig={
                    {
                        className:"bg-primary clr-white"
                    }
                }
                >
                {
                    props.typeRequest === "create" ? 'Criar cliente' : 'Atualizar cliente'
                }
            </Button>
        </form>
    );
}

export default ClientForm