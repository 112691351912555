import { AxiosResponse } from 'axios'
import Request from '../../../util/Request'

const ConsultarGestor = async (
    $id: string,
    success: ((response: AxiosResponse) => void),
    error: ((error: AxiosResponse) => void)
) => await Request.get(`/admin/manager/${$id}`).then(success).catch(error)


export default ConsultarGestor