import React, {useEffect, useState} from 'react';
import AtualizarComment from '../../../../../services/Manager/Comment/AtualizarComment';
import ConsultarComment from '../../../../../services/Manager/Comment/ConsultarComment';
import Error from '../../../../../util/Modals/Error';
import Success from '../../../../../util/Modals/Success';
import Warning from '../../../../../util/Modals/Warning';
import Button from '../../../../components/Form/Button';
import ModalContent from '../../../../components/ModalContent';

export interface IAtualizarComentarioProps {
    onClose: (() => void),
    statusView: boolean,
    idComment: string
}

const AtualizarComentario: React.FunctionComponent<IAtualizarComentarioProps> = (props) =>  {

    const defaultFormData = {
        comment: '',
        id: ''
    }

    const [formData, setFormData] = useState(defaultFormData)

    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => setFormData({...formData, comment: event.target.value})
    
    const onUpdate = (event: React.ChangeEvent<HTMLFormElement>) => 
    {
        event.preventDefault()

        if(formData.comment === '')
        {
            Warning('Preencha os campos.')
        }else{
            AtualizarComment(formData, () => {
                Success('Atualizado com sucesso', () => window.location.reload())
                setFormData(defaultFormData)
            }, () => Error('Houve um erro ao tentar criar este observação.' ))
        }
    }

    useEffect(() => {
        if(props.idComment)
        {
            ConsultarComment(props.idComment,
                e => {
                    setFormData({...formData, comment: e.comment, id: e.id})
                }, 
                err => {
                    Warning('Não foi possível recuperar seu Conteudo')
                }
            )
        }
    }, [props.idComment])

    return (
        <ModalContent onClickClose={props.onClose} show={props.statusView}>
            <form onSubmit={onUpdate} method="post">
                <div className="input-entry">
                    <p>Observação</p>
                    <input onChange={onChangeInput} type="text" value={formData.comment} />
                </div>
                <Button buttonConfig={{className: "bkg-blue clr-white"}}>
                    Atualizar observação
                </Button>
            </form>
        </ModalContent>
    );
}

export default AtualizarComentario;