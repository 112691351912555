import Modal from "../Modal"

const Error = ($text: string) => {
    Modal.fire({
        title: 'Erro!',
        text: $text,
        icon: 'error',
    })
}

export default Error