import React, {useState} from 'react'
import { LogoComum } from '../assets';
import InputBox from './components/Form/Input';
import Button from './components/Form/Button';
import { Link } from 'react-router-dom';

export interface IEsqueciSenhaProps {}

const EsqueciSenha: React.FunctionComponent<IEsqueciSenhaProps> = (props) =>  {

    const [formUser, setFormUser] = useState({
        email: '',
        password: ''
    })


    const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => 
    {
        event.preventDefault()
    }

    return (
      <div className="full-screen">
        <form method="POST" className="form-login" onSubmit={handleSubmit}>
            <div className="logo-icon">
                <img src={ LogoComum } alt=""/>
            </div>
            <InputBox 
                label="E-mail" 
                configInput={{
                    type: 'text',
                    placeholder: 'Digite seu e-mail',
                    value: formUser.email,
                    onChange: (event: any) => {
                        setFormUser({
                            ...formUser,
                            email: event.target.value
                        })
                    }
                }}
            /> 
            <Button buttonConfig={{className: "clr-white bkg-black bkg-black-hover", style: {marginTop: '10px'}}}>
                Recuperar senha
            </Button>
            <div className="justify-center" style={{display: 'flex', marginTop: '20px'}}>
              <Link to="/entrar">Voltar</Link>
            </div>
        </form>
      </div>
    );
}

export default EsqueciSenha;